<template>
    <div class="editor">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, focused, getMarkAttrs }">
            <div
                    class="menubar"
                    :class="{ 'is-focused': focused }"
                    ref="editorMenuBar"
            >
                <b-field>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="bold"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.bold() }"
                                @click="commands.bold"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="italic"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.italic() }"
                                @click="commands.italic"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="strikethrough"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.strike() }"
                                @click="commands.strike"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="underline"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.underline() }"
                                @click="commands.underline"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="code"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.code() }"
                                @click="commands.code"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                icon-right="paragraph"
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.paragraph() }"
                                @click="commands.paragraph"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                                @click="commands.heading({ level: 1 })"
                        >
                            H1
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                                @click="commands.heading({ level: 2 })"
                        >
                            H2
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                size="is-small"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                                @click="commands.heading({ level: 3 })"
                        >
                            H3
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="list-ul"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.bullet_list() }"
                                @click="commands.bullet_list"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="list-ol"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.ordered_list() }"
                                @click="commands.ordered_list"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="quote-left"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.blockquote() }"
                                @click="commands.blockquote"
                        >
                        </b-button>
                    </p>

                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="code"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.code_block() }"
                                @click="commands.code_block"
                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="link"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.link() }"
                                @click="showLinkMenu(getMarkAttrs('link'))"

                        >
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                                icon-pack="fas"
                                size="is-small"
                                icon-right="image"
                                class="menubar__button"
                                :class="{ 'is-active': isActive.image() }"
                                @click="showImageMenu(getMarkAttrs('link'))"
                        >
                        </b-button>
                    </p>
                </b-field>

            </div>
        </editor-menu-bar>
        <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
                    class="menububble"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >

                <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <b-field >
                        <b-input placeholder="http://"
                                 type="text"
                                 class="menububble__input"
                                 ref="linkInput"
                                 size="is-small"
                                 @keydown.esc="hideLinkMenu"
                                 v-model="linkUrl">
                        </b-input>
                        <p class="control">
                            <b-button
                                    icon-pack="fas"
                                    size="is-small"
                                    icon-right="link"
                                    class="menubar__button"
                                    :class="{ 'is-active': isActive.link() }"
                                    @click="setLinkUrl(commands.link, null)"

                            >
                            </b-button>
                        </p>
                    </b-field>
                </form>

                <form class="menububble__form menububble__form-image" v-if="imageMenuIsActive" @submit.prevent="setImgSrc(commands.image, imgSrc)">
                    <b-field >
                        <b-input placeholder="http://"
                                 type="file"
                                 size="is-small"
                                 class="menububble__input"
                                 ref="imageInput"
                                 @keydown.esc="hideImageMenu"
                                 v-model="imageSrc">
                        </b-input>
                        <p class="control">
                            <b-button
                                    icon-pack="fas"
                                    size="is-small"
                                    icon-right="image"
                                    class="menubar__button"
                                    :class="{ 'is-active': isActive.image() }"
                                    @click="setImgSrc(commands.image, null)"

                            >
                            </b-button>
                        </p>
                    </b-field>
                </form>
            </div>
        </editor-menu-bubble>

        <div class="editor-wrapper" ref="editor">
            <editor-content class="editor__content" :editor="editor"/>
        </div>
    </div>
</template>
<script>

    import storageRepository from '@/repositories/storageRepository.js';
    import ImageUpload from '@/components/common/editor/ImageUpload'
    import Span from '@/components/common/editor/Span'
    import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
    import {
        Blockquote,
        BulletList,
        CodeBlock,
        HardBreak,
        Heading,
        ListItem,
        OrderedList,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
        //Image
    } from 'tiptap-extensions'
    export default {
        name: 'Editor',
        props: ['value', 'user'],
        components: {
            EditorContent,
            EditorMenuBar,
            EditorMenuBubble
        },
        data: function() {
            return {
                editor: new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        //new Image(),
                        new ImageUpload({
                            uploader: (image) => {
                                return this.uploadImage(image)
                            },
                        }),
                        new Span(),
                    ],

                    content: this.valueLocal,
                    onUpdate: this.onUpdateEditor,
                    onBlur: this.onBlurEditor,

                }),
                imageMenuIsActive: false,
                imageSrc: null,
                linkUrl: null,
                linkMenuIsActive: false,
                valueLocal: this.value
            }
        },
        methods: {
            async uploadImage (selectedFile) {
                if (selectedFile.size / 1024 / 1024 > 5) {
                    // handle error
                    return
                }
                // Check the allowed content type
                if (!['image/jpeg', 'image/png', 'image/gif'].includes(selectedFile.type)) {
                    // handle error
                    return;
                }

                // Optionally you can set a state here so as to toggle the editor to readonly mode until the image is uploaded.
                this.imageUploadInProgress = true;
                return await storageRepository.upload(this.user.id, selectedFile);
            },
            onUpdateEditor: function(editor) {
                let value = editor.getHTML();
                if (value.length) {
                    this.$emit('input', value);
                }
            },
            onBlurEditor: function() {
                let value = this.editor.getHTML();
                if (value.length) {
                    this.$emit('input', value);
                }
            },
            showImageMenu(attrs) {
                this.imageSrc = attrs.href;
                console.log(this.imageSrc);
                this.imageMenuIsActive = true;
                this.$nextTick(() => {
                    //this.$refs.imageInput.focus()
                })
            },
            hideImageMenu() {
                this.imgSrc = null;
                this.imageMenuIsActive = false;
            },
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href;
                this.linkMenuIsActive = true;
                this.$nextTick(() => {
                    this.$refs.linkInput.focus()
                })
            },
            hideLinkMenu() {
                this.linkUrl = null;
                this.linkMenuIsActive = false;
            },
            setLinkUrl(command, url) {
                command({ href: url, 'target_': 'blank' });
                this.hideLinkMenu();
            },
            setImgSrc(command, src) {
                command({ src: src});
                this.hideImageMenu();
            },
        },
        created: function () {
            this.editor.setContent(this.valueLocal);
            this.editor.view.dom.classList.add('wysiwyg');
            console.log(this.props);
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        watch: {
            'valueLocal': {
                handler: function (val) {
                    console.log(val);
                    this.editor.setContent(val);
                },
                deep: true
            },

        },
    }
</script>
<style lang="scss">
    .ProseMirror-selectednode {
       outline: 2px solid #3298dc;
    }
    .menubar__button {
        max-width: 20px;
        span {
            font-size: 10px;
        }
    }
    .menububble__form {
        position: absolute;
        min-width: 200px;
        max-width: 300px;
        z-index: 10;
    }
    @media (max-width: 680px) {
        .button.menubar__button {
            & span {
                display: block;
            }
        }
    }
</style>

