<template>
    <div class="userBlock" v-if="loggedIn">
        <div class="userBlock-body">
            <div class="userBlock-name">
                <span class="is-block has-text-grey is-size-7">Вы вошли как</span>
                <router-link :to="'/user/edit/' + user.id">
                    <strong class="is-clipped">{{user.name}} {{user.surname}}</strong>
                </router-link>
            </div>
        </div>

        <div class="userBlock-sidebar">
            <router-link to="/logout" class="userBlock-footer-item">
                <b-icon
                    icon-pack="fas"
                    type="is-dark"
                    icon="sign-out-alt">
                </b-icon>

            </router-link>
        </div>
    </div>
</template>
<script>


    export default {
        name: 'UserBlock',
        props: ['user', 'loggedIn'],
        components: {
        },
        data: function() {
            return {
                loadedData: false,
            }
        },
        methods: {


        },
        created: function () {
        }
    }
</script>
<style lang="scss">
    .userBlock {
        position: absolute;
        bottom: 10px;
        width: 210px;
        border-radius: 4px;
        background: #eee;
        display: flex;
        &-body {
            padding: 20px;
            flex: 8;
        }
        &-sidebar {
            padding: 10px;
            flex: 2;
            & a {
                display: block;
                text-align: center;
                height: 28px;
                opacity: 0.5;
                transition: all 0.3s;
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &-name {

        }
    }

    @media (max-width: 680px) {
        .userBlock {
            position: absolute;
            bottom: 10px;
            width: 44px;
            border-radius: 4px;
            display: block;
            &-body {
                display: none;
            }
            &-sidebar {
                padding: 10px;
                flex: 2;
                & a {
                    display: block;
                    text-align: center;
                    height: 28px;
                    opacity: 0.5;
                    transition: all 0.3s;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
</style>

