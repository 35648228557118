<template>
    <section>
        <div class="is-flex">
            <div class="is-expanded flex-wide">
                <span class="is-block has-text-grey is-size-7" v-if="showOnlyBar !== true">До конца выполнения задачи:</span>
                <b-progress :type="type" :value="timeLeft" class="mt-2" :size="size ? size : 'is-large'" show-value>
                    <span style="color: #000; font-size: 11px;">{{this.barContent}}</span>
                </b-progress>
            </div>
        </div>
    </section>
</template>
<script>

    import textHelper from '@/utils/textHelper';

    export default {
        name: 'DeadLineBlock',
        props: ['task', 'showOnlyBar', 'size'],
        components: {

        },

        data() {
            return {
                timeLeft: this.getTimeLeft(),
                type: 'is-info',
                barContent: '',

            }
        },
        computed: {

        },
        //

        methods: {
            getTimeLeft: function () {
                let start = this.$moment(this.task.created_at);
                let now = this.$moment(new Date());
                let end = this.$moment(this.task.finish_at);
                let durationOverall = this.$moment.duration(end.diff(start));
                let duration = this.$moment.duration(end.diff(now));
                let result = ((durationOverall._milliseconds - duration._milliseconds) / durationOverall._milliseconds) * 100;
                let text = '';

                if (duration._data.days) {
                    text += duration._data.days + ' ' + textHelper.getNoun(duration._data.days, 'день', 'дня', 'дней') + ' '
                }

                if (duration._data.hours) {
                    text += duration._data.hours + ' ' + textHelper.getNoun(duration._data.hours, 'час', 'часа', 'часов') + ' '
                }

                if (duration._data.minutes) {
                    text += duration._data.minutes + ' ' + textHelper.getNoun(duration._data.minutes, 'минута', 'минуты', 'минут') + ' '
                }

                if (duration._data.days === 0 && duration._data.hours === 0 && duration._data.minutes === 0) {
                    text = 'меньше минуты';
                }

               if (duration._milliseconds < 0) {
                   text = 'Просрочено';
               }

                result = result > 100 ? 100 : result;
                if (result === 100) {
                    this.type = 'is-danger'
                }
                this.timeLeft = result;
                this.barContent = text;

                return result;
            }
        },
        created: function () {
            this.getTimeLeft();
            setInterval(() => this.getTimeLeft(), 60000)
        }
    }
</script>
<style lang="scss">

</style>

