import { Mark } from 'tiptap';
export default class Span extends Mark {
    get name() {
        return 'span'
    }
    get schema() {
        return {
            attrs: {
                class: {
                    default: 'some-class',
                },
                ref: {
                    default: '',
                }
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: 'span',
                    getAttrs: dom => ({
                        class: dom.getAttribute('class'),
                        ref: dom.getAttribute('ref'),
                    }),
                },
            ],
            toDOM: node => ['span', {
                ...node.attrs,
            }, 0],
        }
    }
}
