<template>
    <figure :class="'image mx-0 my-0 mr-2 is-pulled-left ' + size">
        <img :src="user.avatar"  :class="'userAvatar is-rounded' + size" alt="Image" v-if="user.avatar">
        <span :class="'is-rounded userAvatar ' + size"  v-else>
            {{user.name[0]}} {{user.surname ? user.surname[0] : ''}}
        </span>
    </figure>
</template>
<script>


    export default {
        name: 'UserAvatar',
        props: ['user', 'size'],
        components: {

        },
        data: function() {
            return {
            }
        },
        methods: {

        },
        created: function () {
        }
    }
</script>
<style lang="scss">
    .userAvatar {
        display: block;
        float: left;
        margin: 2px 10px 0 0;
        background: #eee;
        border-radius: 100%;
        text-align: center;
        color: #666;
        &.is-16x16 {
            font-size: 6px;
            width: 16px;
            height: 16px;
            line-height: 16px;
        }

        &.is-64x64 {
            font-size: 12px;
            width: 64px;
            height: 64px;
            line-height: 64px;
        }
    }
</style>

