
export default {
    api_url: process.env.NODE_ENV === 'production'
        ? 'https://api.ptuzov.ru'
        : 'http://api.ptuzov.x',
    root_host: process.env.NODE_ENV === 'production'
        ? '.ptuzov.ru'
        : '.ptuzov.x',
    pusherKey: 'e23ea3fdd0e2b84b0675',
    pusherCluster: 'eu',
}

