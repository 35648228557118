<template>
    <div>
        <div class="is-flex " :class="classList" v-if="!isEdit">
            <div class="is-expanded flex-wide" v-html="value"></div>

            <b-button
                    type="is-light"
                    class="edit-field"
                    icon-right="edit"
                    icon-pack="fas"
                    @click="changeInputState(field)"
                    v-if="editable" />
        </div>

        <b-field :label="label"
                 label-position="on-border"
                 :type="errors && errors.length ? 'is-danger' : ''"
                 :message="errors && errors.length ? errors : ''"
                 v-if="isEdit === true">
            <b-input :placeholder="label"
                     v-bind:value="value"
                     @input.native="updateValue($event.target.value)"
                     @keyup.native.enter="changeInputState(field)"
                     expanded
                     >
            </b-input>
            <p class="control">
                <button class="button is-primary " @click="changeInputState(field)">
                    <b-icon
                            pack="fas"
                            icon="check-circle"
                    >
                    </b-icon>
                </button>
            </p>
        </b-field>
    </div>
</template>
<script>


    //import UserLink from "../common/UserLink";
    import BIcon from "buefy/src/components/icon/Icon";
    import BButton from "buefy/src/components/button/Button";

    export default {
        name: 'TextInput',
        props: ['isEdit', 'value', 'changeInputState', 'field', 'editable', 'errors', 'classList', 'label'],
        components: {
            BIcon,
            BButton,
            //UserLink,
        },

        methods: {
            updateValue: function (value) {
                this.$emit('input', value);
            }
        }
    }
</script>

