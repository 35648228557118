import axios from 'axios';
import config from '../config';

export default {
    addComment(task_id, text, cb) {
        cb = arguments[arguments.length - 1];
        addComment(task_id, text, cb);
    },

    getComments(task_id, page, cb) {
        cb = arguments[arguments.length - 1];
        getComments(task_id, page, cb);
    },
    deleteComment(comment_id, cb) {
        cb = arguments[arguments.length - 1];
        deleteComment(comment_id, cb);
    },

    editComment(comment_id, text, cb) {
        cb = arguments[arguments.length - 1];
        editComment(comment_id, text, cb);
    },

}

function getComments (task_id, page, cb) {
    axios.get(config.api_url + '/comments/' + task_id + '?page=' + page)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function addComment (task_id, text, cb) {
    let data = {
        body: text
    };
    axios.post(config.api_url + '/comments/add/' + task_id, data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

function editComment (comment_id, text, cb) {
    let data = {
        body: text
    };
    axios.post(config.api_url + '/comments/edit/' + comment_id, data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

function deleteComment (comment_id, cb) {
    axios.post(config.api_url + '/comments/delete/' + comment_id)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

