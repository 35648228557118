<template>
        <section>
                <b-tabs type="is-boxed" class="task-tabs mt-2" v-model="activeProject" @input="params.search = ''">
                    <b-tab-item
                            v-for="project of projects"
                            :key="''+project.id"
                            :value="'' + project.id"
                            :label="project.title"
                    >
                        <template #header>
                            <span> {{project.title}} <b-tag rounded v-if="project.tasks_count"> {{project.tasks_count}} </b-tag> </span>
                            <b-tooltip
                                    label="Этот проект публичный"
                                    position="is-right"
                                    type="is-info"
                                    class="ml-0"
                                    v-if="project.is_public">
                                <b-icon
                                        icon="info"
                                        size="is-small">
                                </b-icon>

                            </b-tooltip>
                        </template>
                        <div class="cards-wrapper"  v-if="isLoaded">
                            <TaskCard v-for="item of tasks" v-bind:key="item.id" :item="item" :isDummy="false"/>
                            <!--TaskCard :project_id="activeProject" /-->
                        </div>
                        <div class="cards-wrapper" v-else>
                            <TaskCard v-for="item of project.tasks_count ? project.tasks_count : dummyCount" v-bind:key="item" item="item" :isDummy="true"/>
                        </div>
                    </b-tab-item>
                </b-tabs>
            <div class="bottom-panel">
                <div class="bottom-panel-item is-flex">
                    <router-link :to="'/tasks/add?project_id=' + activeProject" class="button is-primary is-small">
                        <b-icon pack="fas"
                                icon="plus"
                                size="small">
                        </b-icon>
                        <span>Добавить задачу</span>
                    </router-link>
                </div>
                <div class="bottom-panel-item">

                        <b-input
                                v-model="params.search"
                                placeholder="поиск..."
                                class=""
                                type="search"
                                expanded
                                icon-pack="fas"
                                icon="search"
                                size="is-small">
                        </b-input>
                </div>

                <div class="is-flex bottom-panel-item bottom-panel-sort">
                        <b-select expanded placeholder="Тип сортировки" size="is-small mr-2" v-model="params.sortType" @change.native="changeSorting($event)">
                            <option
                                    v-for="option in sortTypes"
                                    :value="option.id"
                                    :key="option.id">
                                {{ option.title }}
                            </option>
                        </b-select>
                </div>
                <div class="is-flex bottom-panel-item bottom-panel-pagination">
                    <b-pagination
                            v-if="pagination.last_page > 1"
                            :total="pagination.total"
                            v-model="pagination.current"
                            size="is-small"
                            :per-page="pagination.perPage"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                    </b-pagination>
                </div>
            </div>
        </section>
</template>

<script>

    import repository from '@/repositories/taskRepository.js';
    import projectRepository from '@/repositories/projectRepository.js';
    import TaskCard from './TaskCard';

    export default {
        name: 'TaskList',
        components: {
            TaskCard
        },
        data: function() {
            return {
                tasks: [],
                projects: [],
                isLoaded: false,
                isProjectsLoaded: false,
                dummyCount: 24,

                params: {
                    search: '',
                    sortType: localStorage.getItem('taskSortType') ?? 'byDate',
                },
                activeProject: localStorage.getItem('tabProject') ?? 'all',
                pagination: {
                    current: 1,
                    total: '',
                    perPage: '',
                    last_page: '',
                },
                sortTypes: [
                    {
                        id: 'byDate',
                        title: 'По дате создания'
                    },
                    {
                        id: 'byStatus',
                        title: 'По статусу'
                    }
                ],
            }
        },
        methods: {
            changeSorting(event) {
                localStorage.setItem('taskSortType', event.target.value);
                this.params.sortType = event.target.value;
            },
            loadProjects() {
                if (this.activeProject === 'all') {
                    this.getTasks();
                } else {
                    this.getTasksByProject()
                }
            },
            getTasksByProject() {
                this.isLoaded = false;
                repository.getTasksByProject(this.activeProject, this.pagination.current, JSON.parse(JSON.stringify(this.params)),  tasks => {
                    if (tasks.data) {
                        this.pagination.perPage = tasks.data.per_page;
                        this.pagination.total = tasks.data.total;
                        this.pagination.last_page = tasks.data.last_page;

                        this.$nextTick(() => {
                            this.isLoaded = true;
                            this.tasks = tasks.data.data;
                        });
                    }
                });
            },
            getTasks() {
                this.isLoaded = false;
                repository.getTasks(this.pagination.current, JSON.parse(JSON.stringify(this.params)), tasks => {
                    if (tasks.data) {
                        this.pagination.perPage = tasks.data.per_page;
                        this.pagination.total = tasks.data.total;
                        this.pagination.last_page = tasks.data.last_page;
                        this.$nextTick(() => {
                            this.isLoaded = true;
                            this.tasks = tasks.data.data;
                        });

                        if (tasks.data.length === 0) {
                            this.empty();
                        }
                    }
                });
            },

            getProjects() {
                projectRepository.getProjectsList(projects => {
                    if (projects.data) {
                        this.projects = projects.data;
                        this.projects.unshift({
                            id: 'all',
                            title: 'Все',
                        });
                        this.isProjectsLoaded = true;
                    }
                });
            },
            empty() {
                this.$buefy.notification.open({
                    message: 'Задач пока еще нет. Вы можете создать <router-link to="/tasks/add">новую задачу</router-link>',
                    type: 'is-info',
                    hasIcon: true,
                    duration: 5000,
                })
            },
        },
        watch: {
            'pagination.current': {
                handler: function (val, oldVal) {
                    if (val && val !== oldVal) {
                        console.log(val, oldVal);

                        this.loadProjects();
                    }
                },
            },
            'params': {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadProjects();
                },
                deep: true,
            },
            activeProject: function() {
                localStorage.setItem('tabProject', this.activeProject);
                this.loadProjects();
            },
        },
        created: function () {
            this.getProjects();
            this.loadProjects()
        }
    }
</script>

