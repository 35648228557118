<template>
    <div class="card card-task column" :class="isSmall ? 'card-task-small' : ''" v-if="item">
        <DeadLineBlock :task="item" v-if="item.status_id > 2 && item.status_id < 4 && item.finish_at" size="small" :showOnlyBar="true"/>
        <header class="card-task-header"  v-if="!isDummy">
            <router-link :to="'/tasks/show/' + item.id" class="card-task-header-title" :title="item.title">
                {{item.title.length > 44 ? item.title.substring(0, 44) + '...' : item.title}}
            </router-link>
        </header>
        <header class="card-task-header px-2 pt-2" v-else>
            <b-skeleton size="is-large" :active="true"></b-skeleton>
        </header>
        <footer class="card-task-footer is-flex" v-if="!isDummy && !isSmall">
            <div class="content is-size-7 mb-0 flex-wide mr-2 is-block"  v-if="item.author">
                <div class="is-block has-text-grey-light">постановщик:</div>
                <div><UserLink :user="item.author" classListProps="is-inline-flex"/></div>
            </div>
            <div class="content is-size-7 mb-0 ml-2 is-block" v-if="item.executor">
                <div class="is-pulled-left flex-wide mr-2 is-block has-text-grey-light">исполнитель:</div>
                <div><UserLink :user="item.executor" classListProps="is-inline-flex"/></div>
            </div>
        </footer>
        <footer class="card-task-footer is-flex" v-if="isDummy && !isSmall">
            <div class="flex-wide mx-2 is-flex">
                <b-skeleton size="is-large" height="30" :active="true"></b-skeleton>
            </div>
            <div class="flex-wide  mx-2 is-flex">
                <b-skeleton size="is-large"  height="30" :active="true"></b-skeleton>
            </div>
        </footer>
    </div>
    <router-link :to="'/tasks/add?project_id=' + project_id" class="card-add column" v-else-if="!isDummy">
        <div class="card-content">
            <b-icon pack="fas"
            icon="plus"
            size="is-large">
            </b-icon>
            <br/>
            Добавить задачу
        </div>
    </router-link >
</template>
<script>


    import UserLink from "../common/user/UserLink";
    import BIcon from "buefy/src/components/icon/Icon";
    import BSkeleton from "buefy/src/components/skeleton/Skeleton";
    import DeadLineBlock from "./DeadLineBlock";

    export default {
        name: 'TaskCard',
        props: ['item', 'isDummy', 'project_id', 'isSmall'],
        components: {
            BSkeleton,
            BIcon,
            UserLink,
            DeadLineBlock
        },
        data: function() {
            return {
            }
        },
        methods: {

        },
        created: function () {

        }
    }
</script>
<style lang="scss">
    .card-task {
        transition: all 0.3s;
        padding: 1rem 1rem 4rem 1rem;
        position: relative;
        &-small {
            padding: 1rem;
        }

        &:hover {
            transform: scale(1.2);
            z-index: 2;
        }

        &-footer{
            position: absolute;
            bottom: 1rem;
            width: calc(100% - 2rem);
        }

        &-header {
            font-weight: bold;
            margin: 0 0 0px 0;
            &-title {
                line-height: 0.6rem;
            }
        }

        & .progress-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin: 0 !important;
            opacity: 0.8;
            height: 10px;

            & progress {
                border-radius: 0;
                line-height: 10px;
                height: 10px;
            }
            & .progress-value {
                line-height: 10px;
                height: 10px;
                & span {
                    font-size: 9px !important
                }
            }
        }
    }

    @media screen and (max-width: 680px) {
        .card-task {
            margin: 0;
            &:hover {
                transform: scale(1);
            }
        }
    }
</style>

