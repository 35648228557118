<template>
    <div class="tile is-ancestor pt-2" id="dashboard">
        <div class="tile is-vertical is-8">
            <div class="tile">
                <div class="tile is-parent is-vertical">
                    <article class="tile is-child box">
                        <p class="title">Vertical...</p>
                        <p class="subtitle">Top tile</p>

                    </article>
                    <article class="tile is-child box">
                        <p class="title">...tiles</p>
                        <p class="subtitle">Bottom tile</p>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">Middle tile</p>
                        <p class="subtitle">With an image</p>
                        <figure class="image is-4by3">
                            <img src="https://bulma.io/images/placeholders/640x480.png">
                        </figure>
                    </article>
                </div>
            </div>
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <p class="title">Wide tile</p>
                    <p class="subtitle">Aligned with the right tile</p>
                    <div class="content">
                        <!-- Content -->
                    </div>
                </article>
            </div>
        </div>
        <div class="tile is-parent">
            <article class="tile is-child box">
                <div class="content">
                    <p class="title">Задачи</p>

                    <div class="content list-content">
                        <b-loading :is-full-page="false" v-model="isTasksLoading" :can-cancel="true" v-if="isTasksLoading"></b-loading>
                        <div v-if="tasks.length === 0">
                            <span class="has-text-grey">Задач пока нет</span><br/>
                        </div>
                        <TaskCard :isSmall="true" v-for="item of tasks" v-bind:key="item.id" :item="item" :isDummy="false"/>
                    </div>
                </div>
                <div class="box-footer">
                    <div class="box-footer-item">
                        <router-link class="button is-primary is-expanded is-block" to="/tasks/add">
                            Создать задачу
                            <b-icon pack="fas"
                                    icon="tasks"
                                    class="ml-1"
                                    >
                            </b-icon>
                        </router-link>
                    </div>
                </div>

            </article>
        </div>
    </div>
</template>

<script>
    import repository from '../repositories/taskRepository.js';
    import TaskCard from "./tasks/TaskCard";
    export default {
        name: 'Dashboard',
        components: {
            TaskCard

        },
        data: function() {
            return {
                tasks: [],
                isTasksLoading: false,
                pagination: {
                    current: 1,
                    total: '',
                    perPage: '',
                    last_page: '',
                },
            }
        },
        methods: {
            getTasks() {
                this.isTasksLoading = true;
                repository.getActiveTasks(this.pagination.current, tasks => {
                    this.isTasksLoading = false;
                    if (tasks.data.data.length) {
                        this.tasks = tasks.data.data;
                    }
                });
            }
        },
        created: function () {
            this.getTasks();
        }
    }
</script>

<style lang="scss">
    #dashboard {

    }

    .list-content {
        background: #fff;
        & ul {
            list-style: none;
        }
    }

    .tile {
        & .box {
            border-radius: 0;
            position: relative;
        }
    }
    .box-footer {
        background: #fff;
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        border-top: 1px solid #eee;
    }
</style>
