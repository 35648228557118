import axios from 'axios';
import cookieHelper from './cookie';
import config from './config';

export default {
    login (email, pass, cb) {
        cb = arguments[arguments.length - 1];
        //if (localStorage.token) {
        if (cookieHelper.getCookie('token')) {
            if (cb) cb(true, []);
            this.onChange(true);
            return
        }
        pretendRequest(email, pass, (res) => {
            if (res.authenticated) {
                cookieHelper.setCookie('token', res.token);
                //localStorage.token = res.token;
                if (cb) cb(true, []);
                this.onChange(true);
            } else {
                if (cb) cb(false, res.errors);
                this.onChange(false);
            }
        })
    },

    refreshToken (cb) {
        cb = arguments[arguments.length - 1];
        //if (localStorage.token) {

        pretendRefreshRequest((res) => {
            if (res.authenticated) {
                cookieHelper.setCookie('token', res.token);
                if (cb) cb(true, []);
            } else {
                if (cb) cb(false, res.errors);
            }
        })
    },

    register (user, cb) {
        cb = arguments[arguments.length - 1];
        if (cookieHelper.getCookie('token')) {
            if (cb) cb(true, []);
            this.onChange(true);
            return
        }
        console.log(user);
        pretendRegisterRequest(user, (res) => {
            if (res.registered) {
                cookieHelper.setCookie('token', res.token);
                if (cb) cb(true, []);
                this.onChange(true);
            } else {
                if (cb) cb(false, res.errors);
                this.onChange(false);
            }
        })
    },

    appendOrg (org, cb) {
        cb = arguments[arguments.length - 1];

        pretendAddOrgRequest(org, (res) => {
            if (res.appended) {
                if (cb) cb(true, []);
            } else {
                if (cb) cb(false, res.errors);
            }
        })
    },

    appendProject (project, cb) {
        cb = arguments[arguments.length - 1];

        pretendAddProjectRequest(project, (res) => {
            if (res.appended) {
                if (cb) cb(true, []);
            } else {
                if (cb) cb(false, res.errors);
            }
        })
    },

    getToken () {
        //return localStorage.token;
        return cookieHelper.getCookie('token');
    },

    logout (cb) {
        //delete localStorage.token;
        logout();
        if (cb) cb()
        this.onChange(false)
    },

    loggedIn () {
        //return !!localStorage.token;
        return !!cookieHelper.getCookie('token');
    },

    onChange () {},

    checkAuth(cb) {
        getAuthData(cb);
    },

    getUserData(cb) {
        if (localStorage.getItem('user') === null) {
            getAuthData(response => {
                localStorage.setItem('user', JSON.stringify(response.user));
                if (cb) {
                    cb(response.user);
                }
            })
        } else {
            if (cb) {
                cb(JSON.parse(localStorage.getItem('user')));
            }
        }
    },
}


function logout() {
    cookieHelper.deleteCookie('token');
    localStorage.removeItem('user');
}

function pretendRequest (email, pass, cb) {
    let data = {
        username: email,
        password: pass,
        errors: {},
    };
    axios.post(config.api_url + '/auth/login', data)
        .then((response) => {
            cb({
                authenticated: true,
                token: response.data.access_token
            })
        })
        .catch((error) => {
            cb({
                authenticated: false,
                token: false,
                errors: error.response.data.errors
            })
        });
}

function pretendRefreshRequest (cb) {
    axios.post(config.api_url + '/auth/refresh')
        .then((response) => {
            cb({
                authenticated: true,
                token: response.data.access_token
            })
        })
        .catch((error) => {
            cb({
                authenticated: false,
                token: false,
                errors: error.response.data.errors
            })
        });
}


function pretendRegisterRequest(user, cb) {
    axios.post(config.api_url + '/auth/register', user)
        .then((response) => {
            console.log(response.data);
            cb({
                registered: true,
                token: response.data.access_token
            })
        })
        .catch((error) => {
            cb({
                registered: false,
                token: false,
                errors: error.response.data.errors
            })
        });
}


function pretendAddProjectRequest(project, cb) {
    axios.post(config.api_url + '/auth/appendProject', project)
        .then((response) => {
            console.log(response.data);
            cb({
                appended: true,
            })
        })
        .catch((error) => {
            cb({
                appended: false,
                errors: error.response.data.errors
            })
        });
}

function pretendAddOrgRequest(project, cb) {
    axios.post(config.api_url + '/auth/appendOrg', project)
        .then((response) => {
            console.log(response.data);
            cb({
                appended: true,
            })
        })
        .catch((error) => {
            cb({
                appended: false,
                errors: error.response.data.errors
            })
        });
}


function getAuthData (cb) {
    if (cookieHelper.getCookie('token')) {
        axios.defaults.headers.common['Authorization'] = 'bearer' + cookieHelper.getCookie('token');
        axios.post(config.api_url + '/auth/me')
        .then((response) => {
            cb({
                user: response.data,
            });
            localStorage.setItem('user', JSON.stringify(response.data));
            if (!response.data.id) {
                logout();
            }
        })
        .catch(() => {
            logout();
        });
    }
}
