<template>
  <div id="app" :class="$route.path !== '/login' && $route.path !== '/register' ? '' : 'one-column'">
          <div class="column-sidebar" v-if="($route.path !== '/login' && $route.path !== '/register') && loggedIn && user">
              <Sidebar :loggedIn="loggedIn" :user="user"/>
          </div>

          <div class="column-content">
              <template v-if="$route.matched.length">
                  <router-view @updateAuthData="updateAuthData" :user="user"></router-view>
              </template>
              <template v-else>
                  <p>Здесь нет представления</p>
              </template>
          </div>
      </div>
</template>

<script>
    import auth from './auth'
    import Sidebar from "./components/common/Sidebar";


    export default {
        name: 'App',
        components: {
            Sidebar,
        },
        data () {
            return {
                loggedIn: auth.loggedIn(),
                user: false,
                checkAuthTimer: 60000,
            }
        },
        methods: {
            updateAuthData() {
                auth.getUserData(result => {
                    this.$nextTick(() => {
                        this.loggedIn = auth.loggedIn();
                        this.user = result;
                    });
                });
            },
            checkAuth() {
                auth.checkAuth(result => {
                    console.log(result);
                });
            },
        },
        beforeRouteEnter() {

        },
        created () {
            this.updateAuthData();

            setInterval(() => this.checkAuth(), this.checkAuthTimer);
        },
        watch: {
            'user': {
                handler: function (val) {
                    console.log(val)
                },
                deep: true
            }
        },
    }
</script>

<style lang="scss">
    .column-sidebar {
        padding: 0;
        margin: 0;

    }
    .column-content {
        max-width: calc(100% - 260px);
        margin: 0 0 0 250px;
        min-height: 100vh;
        padding-top: 0;
    }

    .tab-content {
        padding: 1rem 0 !important
    }
    @media (max-width: 680px) {
        .button:not(.mobile-button) span:not(.icon) {
            display: none;
        }
        .button .icon {
            margin-left: -0.5rem!important;
        }
        .title {
            font-size: 20px;
        }
        .column-sidebar {
            position: relative;
            min-width: 80px !important;
            max-width: 80px;
            padding: 0;
            margin: 0;
            & .sidebar-page {
                max-width: 60px;
                padding: 0;
            }
        }

        .p-1 {
            padding:0.5rem;
            min-height: 100vh;

        }

        .menu-label {
            display: none;
        }

        .column-content {
            max-width: calc(100% - 60px);
            margin: 0 0 0 60px;

            min-height: 100vh;

            & .tab-content {

            }
        }
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    }
</style>
