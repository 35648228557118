<template>
    <div class="card card-question-btn" :style="'position: absolute; top: ' + this.y +'px; left: ' + this.x + 'px;'">
        <b-field>
            <p class="control">
                <b-button icon-pack="fas" icon-right="question-circle" type="is-primary" @click="createQuestion">Задать вопрос</b-button>
            </p>
            <p class="control">
                <b-button icon-pack="fas" icon-right="times" outlined="" type="is-primary" @click="hideQuestionBtn"></b-button>
            </p>
        </b-field>
    </div>
</template>

<script>
    //import BIcon from "buefy/src/components/icon/Icon";
    import BButton from "buefy/src/components/button/Button";
    export default {
        name: 'QuestionBtn',
        props: ['x', 'y', 'createQuestion', 'hideQuestionBtn'],
        components: {
            BButton
            //BIcon

        },
        data () {
            return {

            }
        },
        methods: {

        },
        created () {

        }
    }
</script>
<style lang="scss">
    .card-question-btn {
        z-index: 11;
        max-width: 240px;
        position: absolute;
        padding: 10px;
        background: #fff;
    }
</style>
