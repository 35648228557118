<template>
    <div class="field pt-4" v-if="$route.params.id">
        <div class="field-body" v-if="status_id !== undefined">
            <div class="field has-addons">
                <div class="control" v-if="status_id !== 5 && ((statusMap[status_id].role === 'executor' && isExecutor) || (statusMap[status_id].role === 'author' && isAuthor))">
                    <b-button
                            icon-pack="fas"
                            :icon-right="statusMap[status_id].icon"
                            class=""
                            type="is-primary"
                            outlined
                            @click="changeTaskStatus(statusMap[status_id].next)"
                    >
                        {{statusMap[status_id].action}}
                    </b-button>
                </div>
                <div class="control" v-if="status_id === 4 && (statusMap[status_id].role === 'author' && isAuthor)">
                    <b-button
                            icon-pack="fas"
                            :icon-right="statusMap[6].icon"
                            class=""
                            type="is-primary"
                            outlined
                            @click="changeTaskStatus(statusMap[6].next)"
                    >
                        {{statusMap[6].action}}
                    </b-button>
                </div>

                <div class="control" v-if="status_id === 2 && (statusMap[status_id].role === 'author' && isAuthor)">
                    <b-button
                            icon-pack="fas"
                            :icon-right="statusMap[7].icon"
                            class=""
                            type="is-danger"
                            outlined
                            @click="changeTaskStatus(statusMap[7].next)"
                    >
                        {{statusMap[7].action}}
                    </b-button>
                </div>

                <div class="control" v-if="canDelete">
                    <b-button
                            outlined
                            type="is-danger"
                            icon-pack="fas"
                            icon-right="trash-alt"
                            @click="deleteTask"
                    >
                        Удалить
                    </b-button>
                </div>
                <div class="control pl-2">
                    <p><span class="is-block has-text-grey is-size-7">Текущий статус: </span>
                        <b-icon pack="fas" :icon="statusMap[status_id].currentIcon" v-if="statusMap[status_id].currentIcon"/>
                        <strong>{{statusMap[status_id]['title']}}</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <hr v-else/>
</template>

<script>
    import BIcon from "buefy/src/components/icon/Icon";
    export default {
        name: 'TaskStatus',
        props: [
            'changeTaskStatus',
            'deleteTask',
            'closeTask',
            'canEdit',
            'canDelete',
            'status_id',
            'isExecutor',
            'isAuthor',
        ],
        components: {
            BIcon

        },
        data () {
            return {
                statusMap: {
                    0: {
                        title: 'Новая задача',
                        icon: 'clock',
                        action: 'Взять в работу',
                        role: 'executor',
                        next: 1,
                    },
                    1: {
                        title: 'Задача на оценке',
                        action: 'Оценить',
                        icon: 'check-square',
                        role: 'executor',
                        next: 2,
                    },
                    2: {
                        title: 'Задача на согласовании',
                        icon: 'code',
                        action: 'Передать в работу',
                        role: 'author',
                        next: 3,
                    },
                    3: {
                        title: 'Задача в производстве',
                        icon: 'check-double',
                        action: 'Завершить задачу',
                        role: 'executor',
                        next: 4,
                    },
                    4: {
                        title: 'Проверка задачи',
                        icon: 'check-double',
                        action: 'Принять задачу',
                        role: 'author',
                        next: 5,
                    },
                    5: {
                        title: 'Задача закрыта',
                        currentIcon: 'lock',
                    },
                    6: {
                        title: 'Задача на согласовании',
                        icon: 'code',
                        action: 'Вернуть в работу',
                        role: 'author',
                        next: 8,
                    },
                    7: {
                        title: 'Задача на согласовании',
                        icon: 'clock',
                        action: 'Доработать постановку',
                        role: 'author',
                        next: 0,
                    },
                    8: {
                        title: 'Задача повторно в производстве',
                        icon: 'check-double',
                        action: 'Завершить задачу',
                        role: 'executor',
                        next: 4,
                    },
                },

            }
        },
        methods: {
            getNextStatus: function () {
                return this.statusMap[this.statusMap[this.status_id].next];
            }
        },
        created () {
            if (this.status_id) {
                console.log(this.getNextStatus());
            }
        }
    }
</script>
