<template>
    <section>
        <article :class="comment.is_system ? 'comment comment-system' : 'comment'">
            <b-dropdown aria-role="list" class=" comment-menu" v-if="user.id === comment.user_id && comment.is_system !== 1">
                <b-button class="button is-text" icon-pack="fas" icon-right="ellipsis-h" slot="trigger" slot-scope="{  }"/>
                <b-dropdown-item aria-role="listitem" @click="editCommentLocal(comment.id)">Редактировать</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteComment(comment.id)">Удалить</b-dropdown-item>
            </b-dropdown>
            <div class="comment-body wysiwyg" v-html="comment.body" v-if="!isEdit"></div>
            <div class="comment-body" v-else>
                <div class="editor-wrapper mt-6" :ref="'comment_' + comment.id">
                    <Editor  class="editor__content" v-model="comment.body" ref="commentComponent"/>
                </div>
                <nav class="is-flex mt-2">
                    <b-button
                            type="is-primary"
                            size="is-small"
                            @click="setCommentLocal(comment.id, comment.body)"
                            icon-pack="fas"
                            icon-right="comment">
                        Изменить
                    </b-button>
                    <b-button
                            class="ml-2"
                            type="is-light"
                            size="is-small"
                            @click="isEdit = !isEdit"
                            icon-pack="fas"
                            icon-right="times">
                        Отменить
                    </b-button>
                </nav>
            </div>

            <div class="comment-footer">
                <div class="comment-footer-item" v-if="comment.is_system !== 1">
                    <UserLink :user="comment.user"/>
                </div>
                <div class="comment-footer-item is-size-7 pt-1 has-text-grey-light">
                    {{ comment.created_at | moment("Do MMMM YYYY, HH:mm") }}
                    <span v-if="comment.created_at !== comment.updated_at">
                        <b-icon pack="fas" icon="edit" title="Отредактировано"></b-icon>
                    </span>
                </div>
            </div>
        </article>
    </section>
</template>
<script>
    import UserLink from "../../common/user/UserLink";
    import Editor from "@/components/common/Editor";

    export default {
        name: 'CommentItem',
        props: ['comment', 'user', 'editComment', 'deleteComment'],
        components: {
            UserLink,
            Editor
        },
        data: function() {
            return {
                activeMenu: false,
                editorOptions: 1,
                isEdit: false,
            }
        },

        methods: {

            setCommentLocal: function(id, text) {
                this.editComment(id, text);
                this.isEdit = false;
            },
            editCommentLocal: function() {
                this.isEdit = true;
            }
        },
        watch: {


        },
        created: function () {
        }
    }
</script>
<style lang="scss">
    .ProseMirror {
        background: rgba(255, 255, 255, 0.6);
        padding: 0.5rem;
    }
    .comment {
        width: 100%;
        margin: 0 0 2rem 0;
        position: relative;

        &-body {
            position: relative;
            background: #eee;
            border-radius: 4px;
            padding: 1rem;
            margin: 0 0 0.5rem 0;
            & p {
                margin: 0;
                padding: 0;
            }
        }
        &-footer {
            display: inline-flex;
            &-item {
                padding: 0 10px;
            }
        }
        &-menu {
            position: absolute;
            top: 0;
            z-index: 1;
            right: 0;
            & .button {
                z-index: 2;
                opacity: 0.4;
                text-decoration: none;
            }

            &:hover {
                & .button {
                  opacity: 1;
                }
            }
        }

        &-system {
            & .comment-body {
                background: #7957d5;
                color: #fff;
                & strong {
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: 680px) {
        .comment {
            &-footer {
                &-item {
                    & a {
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>

