
<template>
  <div class="chat-outer">
    <ul class="chat-inner">
      <li :class="user.profile.id == message.profile_id ? 'chat-item-right chat-item' : 'chat-item-left chat-item'" v-bind:key="message.id" v-for="message in messages">
        <div class="chat-item-header">
          <strong class="primary-font">{{ message.profile ? message.profile.name : 'unknown' }}</strong>
        </div>
        <div class="chat-item-body" v-html="message.message ? message.message.replace(/\n/g, '<br />') : 'deleted'"></div>
        <div class="chat-item-footer">{{ new Date( message.created_at).toLocaleString() }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ChatMessages',
  props: ['messages', 'user']
};
</script>
<style lang="scss">
  .chat {
    &-outer {
      height: calc(100vh - 100px);
      overflow-y: scroll;
    }

    &-inner {
      min-height: 100%;
    }

    &-item {
      background: #f5f8fc;
      border-radius: 5px;
      padding: 10px 20px;
      margin: 20px 40% 20px 20px;
      max-width: 60%;
      position: relative;
      &-right {
        margin: 20px 20px 20px 40%;
      }

      &-footer {
        opacity: 0.4;
        font-size: 11px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
</style>
