import axios from 'axios';
import config from '../config';

export default {
    addQuestion(task_id, ref_id, text, question, cb) {
        cb = arguments[arguments.length - 1];
        addQuestion(task_id, ref_id, text, question, cb);
    },

    resolveQuestion(q_id, cb) {
        cb = arguments[arguments.length - 1];
        resolveQuestion(q_id, cb);
    },

    addAnswer(q_id, text, cb) {
        cb = arguments[arguments.length - 1];
        addAnswer(q_id, text, cb);
    },

    getQuestions(task_id, page, cb) {
        cb = arguments[arguments.length - 1];
        getQuestions(task_id, page, cb);
    },
    getResolvedQuestions(task_id, page, cb) {
        cb = arguments[arguments.length - 1];
        getResolvedQuestions(task_id, page, cb);
    },
    deleteQuestion(q_id, cb) {
        cb = arguments[arguments.length - 1];
        deleteQuestion(q_id, cb);
    },

    editQuestion(q_id, text, cb) {
        cb = arguments[arguments.length - 1];
        editQuestion(q_id, text, cb);
    },

}

function getQuestions (task_id, page, cb) {
    axios.get(config.api_url + '/questions/' + task_id + '?page=' + page)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function getResolvedQuestions (task_id, page, cb) {
    axios.get(config.api_url + '/questions/resolved/' + task_id + '?page=' + page)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function addQuestion (task_id, ref_id, text, question, cb) {
    let data = {
        text: text,
        question: question,
        ref_id: ref_id,
    };
    axios.post(config.api_url + '/questions/add/' + task_id, data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

function addAnswer(q_id, text, cb) {
    let data = {
        text: text,
    };
    axios.post(config.api_url + '/questions/answer/' + q_id, data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}
function resolveQuestion(q_id, cb) {
    axios.post(config.api_url + '/questions/resolve/' + q_id)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

function editQuestion(q_id, text, cb) {
    let data = {
        body: text
    };
    axios.post(config.api_url + '/questions/edit/' + q_id, data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

function deleteQuestion (q_id, cb) {
    axios.post(config.api_url + '/questions/delete/' + q_id)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                errors: error.response ? error.response.data.errors : false,
                data: []
            })
        });
}

