<template>
    <section>
        <h1 class="title">Результат выполнения задачи</h1>

        <div class="editor mt-6 mb-2">
            <Editor v-model="result" ref="resultComponent"/>
        </div>
        <b-field>
            <b-button
                    type="is-primary"
                    @click="setResult"
                    icon-pack="fas"
                    icon-right="check-double">
                Отправить
            </b-button>
        </b-field>
    </section>
</template>
<script>

    import Editor from "@/components/common/Editor";
    import repository from '@/repositories/taskRepository.js';
    import BField from "buefy/src/components/field/Field";
    import BButton from "buefy/src/components/button/Button";
    export default {
        name: 'ResultPopup',
        props: ['task', 'updateStatus', 'statusId', 'taskId', 'close'],
        components: {
            BButton,
            BField,
            Editor
        },
        data() {
            return {
                result: '',
            }
        },
        computed: {

        },
        //

        methods: {
            setResult() {
                repository.setResult(this.task.id, this.result, result => {
                    if (result.errors) {
                        this.errors = result.errors;
                    } else {
                        this.updateStatus(this.statusId, this.task.id);
                        this.close();
                    }
                })
            },
        },
        created: function () {

        }
    }
</script>
<style lang="scss">

</style>

