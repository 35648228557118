import axios from 'axios';
import config from '../config';

export default {
    getProjectData (id, cb) {
        cb = arguments[arguments.length - 1];
        getProject(id, cb);
    },

    getProjects (cb) {
        cb = arguments[arguments.length - 1];
        getProjects(cb);
    },

    getProjectsList (cb) {
        cb = arguments[arguments.length - 1];
        getProjectsList(cb);
    },

    searchProjectByTitle (q, cb) {
        cb = arguments[arguments.length - 1];
        searchProject(q, cb);
    }
}

function getProject (id, cb) {
    axios.get(config.api_url + '/projects/getProject/' + id)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function getProjects (cb) {
    axios.get(config.api_url + '/projects/')
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function getProjectsList(cb) {
    axios.get(config.api_url + '/projects/all')
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function searchProject (q, cb) {
    axios.get(config.api_url + '/projects/search/' + q)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}
