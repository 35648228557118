import axios from 'axios';
import config from '../config';

export default {
    getChats (page, params, cb) {
        cb = arguments[arguments.length - 1];
        getChats(page, params, cb);
    },

    getChat (id, page, cb) {
        cb = arguments[arguments.length - 1];
        getChat(id, page, cb);
    },

    sendMessage(message, cb) {
        cb = arguments[arguments.length - 1];
        sendMessage(message, cb);
    },
}

function getChats (page, params, cb) {
    axios.get(config.api_url + '/chats' + '?page=' + page + '&' + new URLSearchParams(params).toString())
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function getChat (id, page, cb) {
    axios.get(config.api_url + '/chats/' + id + '?page=' + page)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function sendMessage (message, cb) {
    axios.post(config.api_url + '/chats/add', message)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}
