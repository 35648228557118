import axios from 'axios';
import config from '../config';

export default {
    getTasks (page, params, cb) {
        cb = arguments[arguments.length - 1];
        getTasks(page, params, cb);
    },

    getActiveTasks (page, params, cb) {
        cb = arguments[arguments.length - 1];
        getActiveTasks(page, params, cb);
    },

    getTasksByProject (projectId, page, params, cb) {
        cb = arguments[arguments.length - 1];
        getTasksByProject(projectId, page, params, cb);
    },

    getTask (id, cb) {
        cb = arguments[arguments.length - 1];
        getTask(id, cb);
    },

    updateTask (id, task, cb) {
        cb = arguments[arguments.length - 1];
        updateTask(id, task, cb);
    },

    updateStatus (id, statusId, cb) {
        cb = arguments[arguments.length - 1];
        updateStatus(id, statusId, cb);
    },

    setDeadLine(taskId, finish, cost, hours, cb) {
        cb = arguments[arguments.length - 1];
        setDeadLine(taskId, finish, cost, hours, cb);
    },

    setResult(taskId, body, cb) {
        cb = arguments[arguments.length - 1];
        setResult(taskId, body, cb);
    },

    setDecline(taskId, body, cb) {
        cb = arguments[arguments.length - 1];
        setDecline(taskId, body, cb);
    },

    setAccept(taskId, body, cb) {
        cb = arguments[arguments.length - 1];
        setAccept(taskId, body, cb);
    },

    deleteTask(id, cb) {
        cb = arguments[arguments.length - 1];
        deleteTask(id, cb);
    },

    closeTask(id, cb) {
        cb = arguments[arguments.length - 1];
        closeTask(id, cb);
    },

    addTask(task, cb) {
        cb = arguments[arguments.length - 1];
        addTask(task, cb);
    },
}

function getTasks (page, params, cb) {
    axios.get(config.api_url + '/tasks' + '?page=' + page + '&' + new URLSearchParams(params).toString())
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function getActiveTasks (page, params, cb) {
    axios.get(config.api_url + '/tasks/active/' + '?page=' + page + '&' + new URLSearchParams(params).toString())
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function getTasksByProject (projectId, page, params, cb) {
    axios.get(config.api_url + '/tasks/byProject/' + projectId + '?page=' + page + '&' + new URLSearchParams(params).toString())
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function getTask (id, cb) {
    axios.get(config.api_url + '/tasks/' + id)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function updateTask (id, task, cb) {
    axios.post(config.api_url + '/tasks/' + id, task)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}


function updateStatus(id, statusId, cb) {
    axios.post(config.api_url + '/tasks/status/' + id + '/' + statusId)
        .then((response) => {
            cb({
                status: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                status: false
            })
        });
}


function setDeadLine(taskId, finish, cost, hours, cb) {
    let data = {
        finish: finish,
        cost: cost,
        hours: hours
    };
    axios.post(config.api_url + '/tasks/setDeadLine/' + taskId + '/', data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}

function setResult(taskId, body, cb) {
    let data = {
        body: body,
    };
    axios.post(config.api_url + '/tasks/setResult/' + taskId + '/', data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}


function setDecline(taskId, body, cb) {
    let data = {
        body: body,
    };
    axios.post(config.api_url + '/tasks/setDecline/' + taskId + '/', data)
        .then((response) => {
            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}

function setAccept(taskId, cb) {
    axios.post(config.api_url + '/tasks/setAccept/' + taskId + '/', {})
        .then((response) => {

            cb({
                data: response.data,
                errors: false,
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}

function deleteTask(id, cb) {
    axios.post(config.api_url + '/tasks/delete/' + id)
        .then((response) => {
            console.log(response);
            cb({
                result: true
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                result: false
            })
        });
}

function closeTask(id, cb) {
    axios.post(config.api_url + '/tasks/close/' + id)
        .then((response) => {
            console.log(response);
            cb({
                result: true
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                result: false
            })
        });
}

function addTask(task, cb) {
    axios.post(config.api_url + '/tasks/add', task)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            cb({
                data: false,
                errors: error.response.data.errors,
            })
        });
}
