<template>
    <div>

            <div class="editor mt-6 mb-2">
                <Editor v-model="content" ref="commentComponent" :user="user"/>
            </div>
            <nav class="is-flex">
                <b-button
                    type="is-primary"
                    :disabled="!content.length"
                    @click="addCommentLocal()"
                    icon-pack="fas"
                    icon-right="comment">
                    Отправить
                </b-button>


                <b-switch
                    v-model="notifyUsers"
                    true-value="Да"
                    size="is-small"
                    false-value="Нет"
                    class="ml-4"
                    >
                    Уведомлять участников задачи
                </b-switch>
            </nav>
    </div>

</template>
<script>
    import Editor from "@/components/common/Editor";
    import auth from '@/auth.js';
    import BButton from "buefy/src/components/button/Button";

    export default {
        name: 'CommentAdd',
        props: ['addComment'],
        components: {
            BButton,
            Editor
        },
        data: function() {
            return {
                content: '',
                user: false,
                notifyUsers: true,
            }
        },

        methods: {
            addCommentLocal: function () {
                if (this.content && this.content.length === 0) {
                    return;
                }

                this.addComment(this.content);
                this.content = '';
            }
        },
        watch: {

        },
        created: function () {
            auth.getUserData(result => {
                this.user = result;
            });
        }
    }
</script>

