import './assets/scss/main.scss';
import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueRouter from 'vue-router'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueMask from 'v-mask'
import Pusher from 'pusher-js';
const moment = require('moment');
import Config from './config';

require('moment/locale/ru');


window.pusher = new Pusher(Config.pusherKey, {
    cluster: Config.pusherCluster
});

Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(require('vue-moment'), {
    moment
});

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Buefy, { defaultIconPack: 'fas' });

import axios from 'axios';
import auth from './auth';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import TaskList from './components/tasks/TaskList';
import TaskEdit from './components/tasks/TaskEdit';
import ChatList from './components/chats/ChatList';
import ChatItem from './components/chats/ChatItem';
import ProjectList from './components/projects/ProjectList';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(function(config) {
    if (process.env.NODE_ENV !== 'production') {
        if (config.url.includes('?')) {
            config.url = config.url + '&XDEBUG_SESSION_START=1';
        } else {
            config.url = config.url + '?XDEBUG_SESSION_START=1';
        }
    }
    config.headers['Authorization'] = 'bearer ' + auth.getToken();
    return config;
});

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 403) {
        router.push('/')
    }
    if (error.response.status === 401) {
        auth.logout();
        router.push('/login')
    }
    return Promise.reject(error)
});

function requireAuth (to, from, next) {
    if (auth.loggedIn()) {
        next();
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    }

}


const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    linkActiveClass: 'is-active',
    routes: [
        { path: '/', component: Dashboard, beforeEnter: requireAuth},
        { path: '/dashboard', component: Dashboard, beforeEnter: requireAuth },
        { path: '/projects', component: ProjectList, beforeEnter: requireAuth },
        { path: '/tasks', component: TaskList, beforeEnter: requireAuth },
        { path: '/tasks/add', component: TaskEdit, beforeEnter: requireAuth },
        { path: '/tasks/show/:id', component: TaskEdit,  beforeEnter: requireAuth},

        { path: '/chats', component: ChatList, beforeEnter: requireAuth },
        { path: '/chats/show/:id', component: ChatItem,  beforeEnter: requireAuth},


        { path: '/login', component: Login,
            beforeEnter(to, from, next) {
                if (auth.loggedIn()) {
                    next('/')
                }
                next();
            }
        },
        { path: '/register', component: Register},
        { path: '/logout',
            beforeEnter (to, from, next) {
                auth.logout();
                next('/login');
            }
        }
    ]
});

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    render: h => h(App),
});
