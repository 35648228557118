<template>

    <section class="">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Пользователь">
          {{user.name}}
        </b-tab-item>
        <b-tab-item label="Задачи">
          <div class="block" v-for="(task, key) of user.tasks" :key="key">
            <p class="title is-4">{{task.title}}</p>
          </div>
        </b-tab-item>
        <b-tab-item label="Комменты">
          <div class="block" v-for="(comment, key) of user.comments" :key="key">
            <p class="title is-4">{{comment.title}}</p>
          </div>
        </b-tab-item>
        <b-tab-item label="Проекты">
          <div class="box" v-for="(project, key) of user.projects" :key="key">
          <div class="media">
            <div class="media-left" v-if="project.img">
              <figure class="image is-48x48">
                <img :src="project.img" :alt="project.title">
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{{project.title}}</p>
              <p class="subtitle is-6">{{project.body}}</p>
            </div>
          </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>

</template>
<script>


  export default {
    name: 'UserCard',
    props: ['user'],
    components: {

    },
    data: function() {
        return {
            activeTab: 0,
        }
    },
    methods: {

    },
    created: function () {
        console.log(this.user.projects);
    }
  }
</script>

