<template>

    <section>
        <div class="" id="comments">
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true" v-if="isLoading"></b-loading>

            <CommentAdd
                :addComment="addComment"
                class="mb-2"
                :user="user"
            />
            <strong v-if="comments.length">Комментарии:</strong>
            <b-pagination
                    v-if="pagination.last_page > 1"
                    :total="pagination.total"
                    v-model="pagination.current"
                    size="is-small"
                    :per-page="pagination.perPage"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page">
            </b-pagination>
            <div class="comments">
                <CommentItem :editComment="editComment" :deleteComment="deleteComment" :user="user" v-for="comment of comments" v-bind:key="comment.id" :comment="comment"/>
            </div>

            <b-pagination
                    v-if="pagination.last_page > 1"
                    :total="pagination.total"
                    v-model="pagination.current"
                    size="is-small"
                    :per-page="pagination.perPage"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page">
            </b-pagination>

        </div>
    </section>
</template>

<script>

    import CommentItem from '@/components/tasks/comments/CommentItem';
    import CommentAdd from '@/components/tasks/comments/CommentAdd';
    import commentRepository from '@/repositories/commentRepository.js';
    export default {
        name: 'CommentList',
        props: ['updateComments', 'user'],
        components: {
            CommentItem,
            CommentAdd
        },
        data: function() {
            return {
                comments: [],
                projects: [],
                isLoading: false,
                dataLoaded: false,
                pagination: {
                    current: 1,
                    total: '',
                    perPage: '',
                    last_page: 0,
                },
            }
        },
        methods: {
            getComments: function() {
                this.isLoading = true;
                commentRepository.getComments(this.$route.params.id, this.pagination.current, comments => {
                    this.pagination.current = comments.data.current_page;
                    this.pagination.perPage = comments.data.per_page;
                    this.pagination.total = comments.data.total;
                    this.pagination.last_page = comments.data.last_page;
                    this.comments = comments.data.data;
                    this.$nextTick(() => {
                        this.isLoading = false;
                        this.dataLoaded = true;
                    })
                });
            },
            addComment: function(text) {
                commentRepository.addComment(this.$route.params.id, text, result => {
                    if (result.errors) {
                        this.$buefy.toast.open({
                            message: result.errors.body[0],
                            type: 'is-danger'
                        })
                    }
                    this.pagination.current = 1;
                    this.getComments();
                });
            },
            editComment: function(id, text) {
                commentRepository.editComment(id, text, result => {
                    if (result.errors) {
                        this.$buefy.toast.open({
                            message: result.errors.body[0],
                            type: 'is-danger'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: 'Комментарий изменен',
                            type: 'is-success'
                        })
                    }

                    this.getComments();
                })
            },
            deleteComment: function(id) {
                commentRepository.deleteComment(id, result => {
                    if (result.errors) {
                        this.$buefy.toast.open({
                            message: result.errors.body[0],
                            type: 'is-danger'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: 'Комментарий удален',
                            type: 'is-success'
                        })
                    }

                    this.getComments();
                })
            }
        },
        watch: {
            updateComments: {
                handler: function(val) {
                    if (val === true) {
                        this.dataLoaded = false;
                        this.getComments();
                    }
                },
                deep: true,
            },

            pagination: {
                handler: function () {
                    if (this.dataLoaded === true) {
                        this.getComments()
                    }
                },
                deep: true
            }
        },
        created: function () {
            this.getComments();
        }
    }
</script>
<style lang="scss">
    .comments {
        margin: 0;
    }

    @media (max-width: 680px) {

    }
</style>

