<template>
    <section class="section">
        <form @submit.prevent="login" class="box">
            <b-field label="Логин"
                     label-position="on-border"
                     :type="errors.username && errors.username.length ? 'is-danger' : ''"
                     :message="errors.username && errors.username.length ? errors.username : ''"
            >
                <b-input v-model="email"
                         expanded
                         size="is-large">
                </b-input>

            </b-field>
            <b-field label="Пароль"
                     label-position="on-border"
                     :type="errors.password && errors.password.length ? 'is-danger' : ''"
                     :message="errors.password && errors.password.length ? errors.password : ''">
                <b-input type="password"
                         v-model="pass"
                         value=""
                         password-reveal
                         size="is-large"

                         >
                </b-input>

            </b-field>
            <b-button class="button is-primary mobile-button" size="is-large" native-type="submit">Войти</b-button>
            <router-link to="/register" class="is-pulled-right button is-text mt-2">Регистрация</router-link>
        </form>
    </section>
</template>

<script>
    import auth from '../auth'
    import BButton from "buefy/src/components/button/Button";
    export default {
        components: {BButton},
        data () {
            return {
                email: '',
                pass: '',
                error: false,
                errors: {
                    username: [],
                    password: [],
                }
            }
        },
        created: function () {
            /*if (this.$route.query.redirect) {
                this.danger()
            }*/
        },
        methods: {
            danger() {
                this.$buefy.notification.open({
                    duration: 5000,
                    message: `Необходимо авторизоваться`,
                    position: 'is-bottom-right',
                    type: 'is-danger',
                    hasIcon: false
                });
            },
            login () {
                auth.login(this.email, this.pass, (loggedIn, errors) => {
                    if (!loggedIn) {
                        this.error = true
                    } else {
                        this.$router.push(this.$route.query.redirect || '/');
                        this.$emit("updateAuthData", 1)
                    }

                    if (errors) {
                        this.errors = errors;
                    }
                })
            }
        }
    }
</script>

<style>
    .error {
        color: red;
    }
</style>
