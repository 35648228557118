<template>
    <div ref="component">

        <div class="richtextEditor wysiwyg is-flex" :class="classList" v-if="!isEdit">
            <div class="is-expanded flex-wide select-box" v-html="value" ref="target" @mouseup="getSelected($event)"></div>
            <b-button
                    type="is-light"
                    icon-right="edit"
                    class="richtextEditor-edit edit-field"
                    icon-pack="fas"
                    @click="changeInputState(field)"
                    v-if="editable" />
        </div>
        <b-field
             :type="errors && errors.length ? 'is-danger' : ''"
             :message="errors && errors.length ? errors : ''"
             v-if="isEdit === true"
        >
            <Editor v-model="valueLocal" ref="editorComponent" :user="user"/>
            <p class="control">
                <button class="button is-primary button-save-texteditor" @click="changeInputStateLocal(field)">
                    <b-icon
                            pack="fas"
                            icon="check-circle"
                    >
                    </b-icon>
                </button>
            </p>
        </b-field>

    </div>
</template>
<script>
    import BIcon from "buefy/src/components/icon/Icon";
    import BButton from "buefy/src/components/button/Button";
    import Editor from "../Editor";

    export default {
        name: 'RichEditor',
        props: ['isEdit', 'value', 'changeInputState', 'field', 'editable', 'errors', 'classList', 'getSelected', 'user'],
        components: {
            Editor,
            BButton,
            BIcon,
            //BButton,
        },
        data() {
            return {
                imageUploadInProgress: false,
                valueLocal: this.value
            }
        },

        created: function() {
            window.addEventListener('scroll', this.handleScroll);
            this.$nextTick(() => {
                this.valueLocal = this.value;
            })
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },

        methods: {
            handleScroll () {
                this.getPos();
            },
            getPos() {
                const top = this.$refs['component'].getBoundingClientRect().top;

                if (this.isEdit) {
                    const left = this.$refs['editorComponent'].$refs['editorMenuBar'].getBoundingClientRect().left;
                    const height = this.$refs['editorComponent'].$refs['editor'].offsetHeight - 80;

                    if (top < 0 && Math.abs(top) <= height) {
                        this.$refs['editorComponent'].$refs['editorMenuBar'].setAttribute('style', 'position: fixed; left: ' + left + 'px; top: 0px; z-index: 10;');
                        this.$refs['editorComponent'].$refs['editor'].setAttribute('style', 'padding-top: 40px');
                    } else {
                        this.$refs['editorComponent'].$refs['editorMenuBar'].setAttribute('style', '');
                        this.$refs['editorComponent'].$refs['editor'].setAttribute('style', '');
                    }
                }
            },
            changeInputStateLocal(field) {
                if (this.valueLocal.length) {
                    this.$emit('input', this.valueLocal);
                }

                this.changeInputState(field);
            },

            updateValue: function (value) {
                this.$emit('input', value);
            }

        },
        watch: {
            'valueLocal': {
                handler: function (val) {
                    this.$emit('input', val);
                },
                deep: true
            },
            'value': {
                handler: function (val) {
                    this.value = val;
                    this.valueLocal = val;
                },
                deep: true
            }
        },
    }
</script>
<style lang="sass">
    .select-box

        .menububble
        z-index: 10
    .button-save-texteditor
        margin-top: 2px
    .richtextEditor
        position: relative
        margin: 1rem 0 0 0
        max-width: 100%
        &>div
            max-width: 100%
            padding: 20px

    .menubar
        background: #eee
        border: 1px solid #cccccc
        border-bottom: none
        border-top-left-radius: 4px
        border-top-right-radius: 4px
        padding: 5px
        &.is-focused
            display: block !important
    .editor
        width: 100%
        margin-top: -42px
        margin-left: -1px
        max-width: 100%
        position: relative

        .menububble
            position: absolute
        .ProseMirror
            border: 1px solid #ccc
            padding: 20px
            &-focused
                border: 1px solid #666
        &--disabled
            cursor: not-allowed

        .editor__content
            background-color: white
            border-color: #dbdbdb

            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px
            color: #363636
            transition: all 2s
            overflow: auto !important

    @media (max-width: 680px)
        .richtextEditor
            &>div
                max-width: 100%
                padding: 00px
            &-edit
                position: absolute
                right: 0rem

</style>

