<template>
    <div class="sidebar-page">
        <section class="sidebar-layout">
            <b-sidebar
                position="static"
                :mobile="mobile"
                type="is-light"
                open
            >
                <div class="p-1">
                    <b-menu class="is-custom-mobile">
                        <b-menu-list label="Меню">
                            <li
                                v-for="(item, key) of this.menu"
                                v-bind:key="key"
                            >
                                <b-menu-item
                                     :icon="item.icon"
                                     :label="item.label"
                                     :tag="item.tag"
                                     :to="item.to"
                                     :active="false"
                                     v-if="checkAccess(item)"
                                >
                                    <b-menu-item v-for="(i, k) of item.children"
                                                 :icon="i.icon"
                                                 v-bind:key="k"
                                                 :label="i.label"
                                                 :tag="i.tag"
                                                 :to="i.to"
                                    ></b-menu-item>
                                </b-menu-item>
                            </li>
                        </b-menu-list>

                        <b-menu-list label="Меню сайта" v-if="this.user.is_admin === 1">
                            <li
                                    v-for="(item, key) of this.siteMenu"
                                    v-bind:key="key"
                            >
                                <b-menu-item
                                        :icon="item.icon"
                                        :label="item.label"
                                        :tag="item.tag"
                                        :to="item.to"
                                        :active="false"
                                        v-if="checkAccess(item)"
                                >
                                    <b-menu-item v-for="(i, k) of item.children"
                                                 :icon="i.icon"
                                                 v-bind:key="k"
                                                 :label="i.label"
                                                 :tag="i.tag"
                                                 :to="i.to"
                                    ></b-menu-item>
                                </b-menu-item>
                            </li>
                        </b-menu-list>

                        <UserBlock :user="user" :loggedIn="loggedIn"/>
                    </b-menu>
                </div>
            </b-sidebar>
        </section>
    </div>
</template>

<script>

    import UserBlock from "./user/UserBlock";
    export default {
        name: 'Sidebar',
        props: ['loggedIn', 'user'],
        components: {
            UserBlock
        },
        data() {
            return {
                expandOnHover: false,
                mobile: "reduce",
                reduce: false,
                siteMenu: [
                    {
                        icon: 'thumbtack',
                        label: 'Заявки',
                        to: '/site/orders',
                        tag: 'router-link',
                        access: 'admin',
                    },
                    {
                        icon: 'cubes',
                        label: 'Услуги',
                        to: '/site/services',
                        tag: 'router-link',
                        access: 'admin',
                    },
                    {
                        icon: 'image',
                        label: 'Работы',
                        to: '/site/portfolio',
                        tag: 'router-link',
                        access: 'admin',
                    }
                ],
                menu: [
                    {
                        icon: 'columns',
                        label: 'Панель',
                        tag: 'router-link',
                        to: '/dashboard',
                    },
                    {
                        icon: 'tasks',
                        label: 'Задачи',
                        tag: 'router-link',
                        to: '/tasks',
                    },
                    {
                        icon: 'project-diagram',
                        label: 'Проекты',
                        tag: 'router-link',
                        to: '/projects',
                    },
                    {
                        icon: 'ruble-sign',
                        label: 'Биллинг',
                        tag: 'router-link',
                        to: '/billing',
                        access: 'admin'
                    },
                    {
                      icon: 'comments',
                      label: 'Чаты',
                      tag: 'router-link',
                      to: '/chats',
                      access: 'admin'
                    }

                    /*{
                        icon: 'settings',
                        label: 'tmp',
                        tag: 'router-link',
                        to: '/asd',
                    },
                    {
                        icon: 'settings',
                        label: 'Администрирование',
                        expanded: false,
                        to: '/asd2',

                        children: [
                            {
                                icon: 'account',
                                label: 'Пользователи',
                                to: '/users',
                                tag: 'router-link'
                            },
                            {
                                icon: 'account',
                                label: 'asd',
                                to: '/asd2/2asd',
                                tag: 'router-link'
                            },
                            {
                                icon: 'account',
                                label: 'asd',
                                to: '/asd2/asdsadas',
                                tag: 'router-link'
                            }
                        ]
                    }*/
                ],
            };
        },
        methods: {
            checkAccess(item) {
                if (item.access) {
                    if (item.access === 'admin' && this.user.is_admin === 1) {
                        return true;
                    }

                    return false;
                }

                return true;
            }
        },

        created: function() {
        }
    }
</script>

<style lang="scss">
    .b-sidebar {
        width: 100%;
        min-width: 60px;
        .sidebar-content {
            max-width: 100%;
            &.is-mini {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .p-1 {
        padding: 1em;
        min-height: 100vh;
    }
    .sidebar-page {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        // min-height: 100vh;
        max-width: 100%;
        .sidebar-layout {
            display: flex;
            flex-direction: row;
            min-height: 100vh;
            padding: 0;

            max-width: 240px;
            width: 100%;
            // min-height: 100vh;
        }
    }
    @media screen and (max-width: 1023px) {
        .b-sidebar {
            .sidebar-content {
                &.is-mini-mobile {
                    &:not(.is-mini-expand),
                    &.is-mini-expand:not(:hover) {
                        .menu-list {
                            li {
                                a {
                                    span:nth-child(2) {
                                        display: none;
                                    }
                                }
                                ul {
                                    padding-left: 0;
                                    li {
                                        a {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                        .menu-label:not(:last-child) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

</style>
