<template>
    <div class="popup">
        <component :is="this.component.name" v-bind="this.props" :close="close">
            {{this.component}}
        </component>
    </div>
</template>
<script>

    import Vue from 'vue'

    export default {
        name: 'Popup',
        props: ['component', 'props'],
        components: {

        },
        data: function() {
            return {
            }
        },
        methods: {
            close: function() {
                this.$emit('close');
            },
        },
        created: function () {
            console.log(this.props);
        },
        beforeMount() {
            Vue.component(this.component.name, this.component);
        },


    }
</script>
<style lang="scss">
    .popup {
        &-crm {
            & .modal-content {
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                background: #fff;
                padding: 2rem;
                height: 100vh;
                max-width: 40%;
                width: 40%;
            }
        }
    }

    @media (max-width: 680px) {
        .modal-close {
            &::before, &::after {
                background-color: #000;
            }
        }
        .popup {
            &-crm {
                & .modal-content {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
</style>

