<template>
    <a class="userLink-link" :href="'/users/' + user.id" @click="getUserData(user.id, $event)">
        <UserAvatar size="is-16x16" :user="user" v-if="!noPhoto"/>
        <strong class="is-clipped">{{user.name}} {{user.surname}}</strong>
    </a>
</template>
<script>

    import repository from '@/repositories/userRepository.js';
    import UserCard from './UserCard';
    import UserAvatar from "./UserAvatar";
    import Popup from "@/components/common/Popup";


    export default {
        name: 'UserLink',
        props: ['user', 'noPhoto'],
        components: {
            UserAvatar
          
        },
        data: function() {
            return {
                classList: ['user-hover'],
                loadedData: false,
            }
        },
        methods: {
            getUserData(id, e) {
                repository.getUserData(id, user => {
                    this.loadedData = user.data;
                    this.showUser()
                });

                e.preventDefault();

                return false;
            },
            showUser() {
                this.$buefy.modal.open({
                    parent: this,
                    component: Popup,
                    props: {
                        component: UserCard,
                        props: {
                            user: this.loadedData,
                        }
                    },
                    hasModalCard: false,
                    customClass: 'popup-crm',
                    trapFocus: false
                })
            }
        },
        created: function () {
        }
    }
</script>

