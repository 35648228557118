import { Plugin } from 'tiptap'
import { Image } from 'tiptap-extensions'
import config from '@/config.js'

export default class ImageUpload extends Image {
    constructor (options = {}) {
        super(options)
        this.uploader = options.uploader
    }
    get name () {
        return 'image'
    }

    get schema() {
        return {
            attrs: {
                src: {},
                alt: {
                    default: ""
                },
                caption: {
                    default: ""
                },
                'data-href' : {
                    default: ""
                },
                'data-storage_id' : {
                    default: ""
                },
                class: {
                    default: "",
                }
            },
            group: "block",
            draggable: true,
            selectable: true,
            parseDOM: [
                {
                    tag: "img[src]",
                    getAttrs: dom => ({
                        src: dom.getAttribute("src"),
                        alt: dom.getAttribute("alt"),
                        'data-href': dom.getAttribute("data-href"),
                        caption: dom.getAttribute("caption"),
                    })
                }
            ],
            toDOM: node => ["img", node.attrs]
        };
    }

    commands({ type }) {
        return attrs => (state, dispatch) => {
            return dispatch(state.tr.replaceSelectionWith(type.create(attrs)));
        };
    }

    get plugins () {
        const uploader = this.uploader;
        return [
            new Plugin({
                props: {
                    handleDOMEvents: {
                        paste (view, event) {
                            const items = (event.clipboardData || event.originalEvent.clipboardData).items
                            console.log(items);
                            if (!uploader) {
                                return
                            }
                            items.forEach(async item => {
                                const { schema } = view.state;
                                const image = item.getAsFile();
                                // Return here, otherwise copying texts won't possible anymore
                                if (!image) {
                                    return
                                }
                                event.preventDefault();
                                let imgPromise = uploader(image);
                                imgPromise.then((res) => {
                                    const node = schema.nodes.image.create({
                                        src: config.api_url + '/storage/files' + res.data[0].thumb,
                                        'data-href': config.api_url + '/storage/files' + res.data[0].path,
                                        'data-storage_id': res.data[0].id,
                                        class: 'storage',
                                    });
                                    const transaction = view.state.tr.replaceSelectionWith(node);
                                    view.dispatch(transaction);
                                });
                            })
                        },
                        drop (view, event) {
                            const hasFiles = event.dataTransfer.files.length > 0
                            if (!hasFiles) {
                                return
                            }
                            event.preventDefault()
                            const images = event.dataTransfer.files;
                            const { schema } = view.state;
                            const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
                            images.forEach(async image => {
                                if (!uploader) {
                                    return
                                }


                                let imgPromise = uploader(image);
                                imgPromise.then((res) => {
                                    const node = schema.nodes.image.create({
                                        src: config.api_url + '/storage/files' + res.data[0].thumb,
                                        'data-href': config.api_url + '/storage/files' + res.data[0].path,
                                        'data-storage_id': res.data[0].id,
                                        class: 'storage'
                                    });
                                    const transaction = view.state.tr.insert(coordinates.pos, node)
                                    view.dispatch(transaction);
                                });
                            })
                        },
                    },
                },
            }),
        ]
    }
}
