<template>
        <section>
            <h1 class="title">Оценка задачи</h1>
            <b-field label="Дата выполнения"
                     label-position="on-border"
                     :type="errors.finish && errors.finish.length ? 'is-danger' : ''"
                     :message="errors.finish && errors.finish.length ? errors.finish : ''"
            >
                <b-datetimepicker
                        icon-pack="fas"
                        icon="calendar-check"
                        v-model="finish"
                        :mobile-native="false"
                        locale="ru-RU"
                        position="is-bottom-left"
                        placeholder="Выбор даты выполнения"
                        :min-datetime="minDatetime"
                        :max-datetime="maxDatetime"
                        :timepicker="timePicker"
                        trap-focus>


                </b-datetimepicker>
            </b-field>

            <b-field label="Количество часов"
                     label-position="on-border"
                     :type="errors.hours && errors.hours.length ? 'is-danger' : ''"
                     :message="errors.hours && errors.hours.length ? errors.hours : ''"
            >
                <b-numberinput
                        v-model="hours"
                        controls-alignment="right"
                ></b-numberinput>

            </b-field>

            <b-field label="Стоимость работ"
                     label-position="on-border"
                     :type="errors.cost && errors.cost.length ? 'is-danger' : ''"
                     :message="errors.cost && errors.cost.length ? errors.cost : ''"
            >
                <b-numberinput
                        v-model="cost"
                        controls-alignment="right"
                        step="1000"
                ></b-numberinput>

            </b-field>

            <b-field>
                <b-button
                    type="is-primary"
                    @click="setDeadLine"
                    icon-pack="fas"
                    icon-right="clock"
                >
                    Оценить
                </b-button>
            </b-field>
        </section>
</template>
<script>

    import repository from '@/repositories/taskRepository.js';
    import BField from "buefy/src/components/field/Field";
    import BButton from "buefy/src/components/button/Button";
    export default {
        name: 'DeadLinePopup',
        props: ['task', 'updateStatus', 'statusId', 'taskId', 'close'],
        components: {
            BButton,
            BField

        },

        data() {
            const min = new Date();
            const max = new Date();
            min.setDate(min.getDate() - 1);
            max.setDate(max.getDate() + 14);
            return {
                finish: new Date(),
                hours: 0,
                cost: 0,
                errors: [],
                minDatetime: min,
                maxDatetime: max,
                dateOptions: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
                timePicker: {
                    incrementHours: 1
                }

            }
        },
        computed: {

        },
        //

        methods: {

            dateFormat(date) {
                return new Intl.DateTimeFormat('ru-RU', { timeZone: 'Asia/Yekaterinburg' }).format(date)
            },

            setDeadLine() {
                repository.setDeadLine(this.task.id, this.finish ? this.finish.toLocaleDateString('ru-Ru', this.dateOptions) : '', this.cost, this.hours, result => {
                    console.log(result);
                    if (result.errors) {
                        this.errors = result.errors;
                    } else {
                        this.updateStatus(this.statusId, this.task.id);
                        this.close();
                    }
                })
            },
        },
        created: function () {

        }
    }
</script>
<style lang="scss">

</style>

