import axios from 'axios';
import config from '../config';

export default {
    getUserData (id, cb) {
        cb = arguments[arguments.length - 1];
        getUser(id, cb);
    },

    searchUsersByName (q, cb) {
        cb = arguments[arguments.length - 1];
        searchUser(q, cb);
    }
}

function getUser (id, cb) {
    axios.get(config.api_url + '/users/getUser/' + id)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}

function searchUser (q, cb) {
    axios.get(config.api_url + '/users/search/' + q)
        .then((response) => {
            cb({
                data: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            cb({
                data: []
            })
        });
}
