<template>
    <section class="section">

                <div class="container">
                <form @submit.prevent="user">

                <b-steps v-model="activeStep"
                         :animated="true"
                         :rounded="true"
                         :has-navigation="hasNavigation"
                         label-position="bottom"
                         mobile-mode="minimalist"
                         class="box">

                        <b-step-item label="Данные пользователя" :clickable="!loggedIn">
                            <b-field label="Почтовый ящик"
                                     label-position="on-border"
                                     :type="errors.user.email && errors.user.email.length ? 'is-danger' : ''"
                                     :message="errors.user.email && errors.user.email.length ? errors.user.email : ''"
                            >
                                <b-input v-model="user.email"
                                         expanded
                                         :disabled="loggedIn"
                                         size="is-large">
                                </b-input>

                            </b-field>
                            <b-field label="Номер телефона"
                                     label-position="on-border"
                                     :type="errors.user.phone && errors.user.phone.length ? 'is-danger' : ''"
                                     :message="errors.user.phone && errors.user.phone.length ? errors.user.phone : ''"
                                     v-mask="'7 (###) ###-##-##'"
                            >
                                <b-input v-model="user.phone"
                                         expanded
                                         :disabled="loggedIn"
                                         size="is-large">
                                </b-input>

                            </b-field>
                            <b-field label="Имя"
                                     label-position="on-border"

                                     :type="errors.user.name && errors.user.name.length ? 'is-danger' : ''"
                                     :message="errors.user.name && errors.user.name.length ? errors.user.name : ''"
                            >
                                <b-input v-model="user.name"
                                         expanded
                                         :disabled="loggedIn"

                                         size="is-large">
                                </b-input>
                            </b-field>
                            <b-field label="Пароль"
                                     label-position="on-border"
                                     :type="errors.user.password && errors.user.password.length ? 'is-danger' : ''"
                                     :message="errors.user.password && errors.user.password.length ? errors.user.password : ''">
                                <b-input type="password"
                                         v-model="user.password"
                                         value=""
                                         :disabled="loggedIn"
                                         password-reveal
                                         size="is-large"
                                >
                                </b-input>
                            </b-field>
                        </b-step-item>

                        <b-step-item label="Данные проекта" :clickable="!!user.projects">
                            <b-field label="Название проекта"
                                     label-position="on-border"
                                     :type="errors.project.title && errors.project.title.length ? 'is-danger' : ''"
                                     :message="errors.project.title && errors.project.title.length ? errors.project.title : ''"
                            >
                                <b-input v-model="project.title"
                                         expanded
                                         size="is-large">
                                </b-input>

                            </b-field>
                            <b-field label="Описание проекта"
                                     label-position="on-border"
                                     :type="errors.project.body && errors.project.body.length ? 'is-danger' : ''"
                                     :message="errors.project.body && errors.project.body.length ? errors.project.body : ''"
                            >
                                <b-input v-model="project.body"
                                         expanded
                                         type="textarea"
                                         size="is-large">
                                </b-input>

                            </b-field>
                        </b-step-item>
                        <b-step-item label="Данные организации">
                            <b-field label="Название организации"
                                     label-position="on-border"
                                     :type="errors.org.title && errors.org.title.length ? 'is-danger' : ''"
                                     :message="errors.org.title && errors.org.title.length ? errors.org.title : ''"
                            >
                                <b-input v-model="org.title"
                                         expanded
                                         size="is-large">
                                </b-input>
                            </b-field>

                            <b-field label="Тип организации"
                                     label-position="on-border"
                                     :type="errors.org.form && errors.org.form.length ? 'is-danger' : ''"
                                     :message="errors.org.form && errors.org.form.length ? errors.org.form : ''">
                                <b-radio-button v-model="org.form"
                                                v-for="form in forms"
                                                v-bind:key="form.id"
                                                size="is-large"
                                                :native-value="form.id">
                                    <span>{{form.name}}</span>
                                </b-radio-button>
                            </b-field>

                            <b-field label="ИНН"
                                     label-position="on-border"
                                     :type="errors.org.inn && errors.org.inn.length ? 'is-danger' : ''"
                                     :message="errors.org.inn && errors.org.inn.length ? errors.org.inn : ''">
                                <b-input v-model="org.inn"
                                         expanded
                                         size="is-large">
                                </b-input>
                            </b-field>


                            <b-field label="Описание организации"
                                     label-position="on-border"
                                     :type="errors.org.body && errors.org.body.length ? 'is-danger' : ''"
                                     :message="errors.org.body && errors.org.body.length ? errors.org.body : ''"
                            >
                                <b-input v-model="org.body"
                                         expanded
                                         type="textarea"
                                         size="is-large">
                                </b-input>

                            </b-field>
                        </b-step-item>

                        <template
                                slot="navigation"
                                >
                            <div class="">

                                <b-button type="is-primary"
                                          size="is-large"
                                          class=" ml-0 mobile-button"
                                          @click="changeStep()">
                                    Далее
                                </b-button>

                                <router-link to="/login" class="is-pulled-right mr-0 button mt-2 is-text" v-if="!loggedIn">Войти</router-link>
                                <router-link to="/dashboard" class="is-pulled-right mr-0 button mt-2 is-text" v-if="loggedIn">Перейти к использованию</router-link>

                            </div>
                        </template>
                </b-steps>
                </form>
            </div>

    </section>
</template>

<script>
    import auth from '../auth'
    import BButton from "buefy/src/components/button/Button";
    //import BButton from "buefy/src/components/button/Button";
    export default {
        components: {BButton},
        data () {
            return {
                loggedIn: auth.loggedIn(),
                hasNavigation: true,
                activeStep: 0,
                forms: [
                    {
                        id: 1,
                        name: 'ИП'
                    },
                    {
                        id: 2,
                        name: 'ООО'
                    }
                ],
                user: {
                    email: '',
                    name: '',
                    phone: '',
                    password: ''
                },
                project: {
                    title: '',
                    body: '',
                },
                org: {
                    title: '',
                    body: '',
                    form: '',
                    inn: '',
                },
                error: false,
                errors: {
                    user: {},
                    project: {},
                    org: {}
                }
            }
        },
        created: function () {
            if (this.loggedIn) {
                auth.checkAuth(user => {
                    this.user = user.user;
                    if (this.user.projects.length) {
                        this.activeStep = 2;
                    } else {
                        this.activeStep = 1;
                    }

                });
            }
        },

        methods: {
            changeStep() {
                if (this.activeStep === 0) {
                    this.register();
                }

                if (this.activeStep === 1) {
                    this.appendProject();
                }

                if (this.activeStep === 2) {
                    this.appendOrg();
                    let errors = JSON.parse(JSON.stringify(this.errors.org));
                    console.log('tmp');
                    console.log(errors);
                    console.log(errors.length);
                    if (!errors.length) {
                        this.$router.replace('/dashboard')
                    }
                }
            },
            appendProject () {
                auth.appendProject(this.project, (appended, errors) => {
                    if (!appended) {
                        this.error = true
                    } else {
                        this.activeStep = this.activeStep+1;
                    }

                    if (errors) {
                        this.errors.project = errors;
                    }
                })
            },
            appendOrg() {
                auth.appendOrg(this.org, (appended, errors) => {
                    if (!appended) {
                        this.error = true
                    } else {
                        //this.activeStep = this.activeStep+1;
                    }

                    if (errors) {
                        this.errors.org = errors;
                    }
                })
            },
            register () {
                auth.register(this.user, (registered, errors) => {
                    if (!registered) {
                        this.error = true
                    } else {
                        this.loggedIn = true;
                        this.activeStep = this.activeStep+1;
                    }
                    if (errors) {
                        this.errors.user = errors;
                    }
                })
            }
        },
        watch: {
            'errors': {
                handler: function () {
                },
                deep: true
            }
        },
        computed: {
        }
    }
</script>

<style>
    .b-steps .steps + .step-content {
        padding: 1rem 0;
    }
    .error {
        color: red;
    }
</style>
