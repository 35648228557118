<template>
    <div class="question-item question-item-live mb-4"
         @mouseleave="setMouseLeaveQuestion(item.ref_id)"
         @mouseover="setMouseOverQuestion(item.ref_id)"
    >
        <article class="message question-item-quote mb-0 is-info" @click="goTo(item.ref_id)">
            <i>{{item.text}}</i>
        </article>
        <article class="message question-item-text mb-0">
            <p><UserLink :user="item.user" :noPhoto="true"/>:</p>
            <div class="question-item-text-body mt-1">{{item.question}}</div>
        </article>


            <article class="message question-item-text question-item-answer mb-0"  v-for="answer of item.answers" v-bind:key="answer.id">
                <p><UserLink :user="answer.user" :noPhoto="true"/>:</p>
                <div class="question-item-text-body mt-1" v-html="answer.text"></div>
            </article>


        <div v-if="isAddAnswer">
            <b-field
                    label="Ответ"
                    label-position="on-border"
                    class="mb-0 question-item-textarea"
            >
                <b-input type="textarea" class="mb-0" :has-counter="false" v-model="answer"></b-input>
            </b-field>
            <b-button
                    type="is-primary"
                    size="is-small"
                    :disabled="answer.length === 0"
                    @click="addAnswerLocal(item.id)"
                    icon-pack="fas"
                    icon-right="question-circle">
                Отправить
            </b-button>
        </div>
        <div class="question-item-footer" v-else>
            <div class="question-item-footer-item" @click="isAddAnswer = true" v-if="item.is_resolved === 0">
                Ответить
            </div>
            <div class="question-item-footer-item" @click="resolveQuestion(item.id)" v-if="user.id === item.user_id && item.is_resolved === 0">
                Решить
            </div>
        </div>
    </div>
</template>

<script>
    //import BIcon from "buefy/src/components/icon/Icon";
    import BButton from "buefy/src/components/button/Button";
    import UserLink from "../../common/user/UserLink";
    export default {
        name: 'QuestionItem',
        props: ['updateTask', 'goTo', 'item', 'setMouseLeaveQuestion', 'setMouseOverQuestion' ,'addAnswer', 'resolveQuestion', 'user'],
        components: {
            UserLink,
            BButton
        },
        data () {
            return {

                isAddAnswer: false,
                answer: '',
                paginate: {
                    current: 1,
                },
            }
        },
        methods: {
            addAnswerLocal(q_id) {
                this.addAnswer(q_id);
                this.isAddAnswer = false;
                this.answer = '';
            },
        },
        created () {
        },
        watch: {
            'answer': {
                handler: function (val) {
                    this.value = val;
                    this.$emit('input', val);
                },
                deep: true
            }
        },
    }
</script>
