<template>
    <div>
        <div class="is-flex " :class="classList" v-if="!isEdit">
            <div class="is-expanded flex-wide">
                <span class="is-block has-text-grey is-size-7">{{label}}:</span>
                <strong>{{placeholderLocal ? placeholderLocal : value}}</strong>
            </div>

            <b-button
                    type="is-light"
                    class="edit-field"
                    icon-right="edit"
                    icon-pack="fas"
                    @click="changeInputState(field)"
                    v-if="editable" />
        </div>

        <b-field :label="label"
                 label-position="on-border"
                 :type="errors && errors.length ? 'is-danger' : ''"
                 :message="errors && errors.length ? errors : ''"
                 v-if="isEdit === true">

            <b-autocomplete
                    :data="data"
                    :placeholder="label"
                    field="title"
                    v-bind:value="placeholderLocal ? placeholderLocal : value"
                    :loading="isFetching"
                    @typing="getAsyncData"
                    @select="setSelected"
                    @keyup.native.enter="changeInputState(field)"
                    :autofocus="true"
                    expanded>

                <template slot-scope="props">
                    <div class="media">
                        <div class="media-content">
                            {{ props.option.name }} {{ props.option.surname }}
                            <br>
                            <small>
                                Почта {{ props.option.email }},<br/>
                                Телефон <b>{{ props.option.phone }}</b>
                            </small>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
            <p class="control">
                <button class="button is-primary " @click="changeInputState(field)">
                    <b-icon
                            pack="fas"
                            icon="check-circle"
                    >
                    </b-icon>
                </button>
            </p>
        </b-field>
    </div>
</template>
<script>


    //import UserLink from "../common/UserLink";
    import BIcon from "buefy/src/components/icon/Icon";
    import BButton from "buefy/src/components/button/Button";

    export default {
        name: 'AutocompleteUserInput',
        props: ['isEdit', 'value', 'changeInputState', 'field', 'editable', 'errors', 'classList', 'label', 'placeholder', 'searchMethod'],
        components: {
            BIcon,
            BButton,
            //UserLink,
        },
        data() {
            return {
                data: [],
                selected: null,
                isFetching: false,
                placeholderLocal: this.placeholder
            }
        },
        methods: {
            updateValue: function (value) {
                this.$emit('input', value);
            },

            setSelected(option) {
                this.selected = option;
                this.placeholderLocal = option.name + ' ' + option.surname;
                this.$emit('input', option.id);
            },
            getAsyncData: function(name) {
                if (!name.length) {
                    this.data = [];
                    return
                }
                this.isFetching = true;

                this.searchMethod(name, data => {
                    this.data = data.data;
                    this.isFetching = false;
                });
            }
        }
    }
</script>

