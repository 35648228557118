<template>
  <section class="pt-2">

    <b-table
        :data="chats"
        focusable
        ref="table"
        per-page="40"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">

      <b-table-column field="profile_id" label="Профиль" sortable centered v-slot="props">
        {{ props.row.profile.id }}
      </b-table-column>

      <b-table-column field="message" label="Последнее сообщение" sortable centered v-slot="props">
        {{ props.row.message }}
      </b-table-column>

      <b-table-column field="created_at" label="Время сообщения" sortable centered v-slot="props">
            <span class="tag">
                {{ new Date(props.row.created_at).toLocaleString() }}
            </span>
      </b-table-column>

      <b-table-column field="actions" label="Действия" centered v-slot="props">
        <b-button tag="router-link"
                  :to="'/chats/show/' + props.row.chat_id"
                  type="is-link"
                  icon-left="comments"
                  size="is-small">
          Чат
        </b-button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="/static/img/placeholder-128x128.png">
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.id }}</strong>

                <br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Proin ornare magna eros, eu pellentesque tortor vestibulum ut.
                Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <div class="bottom-panel">
      <div class="bottom-panel-item is-flex">

      </div>
      <div class="bottom-panel-item">

        <b-input
            v-model="params.search"
            placeholder="поиск..."
            class=""
            type="search"
            expanded
            icon-pack="fas"
            icon="search"
            size="is-small">
        </b-input>
      </div>

      <div class="is-flex bottom-panel-item bottom-panel-pagination">
        <b-pagination
            v-if="pagination.last_page > 1"
            :total="pagination.total"
            v-model="pagination.current"
            size="is-small"
            :per-page="pagination.perPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>

import repository from '@/repositories/chatRepository.js';

export default {
  name: 'ChatList',
  components: {

  },
  data: function() {
    return {

      chats: [],
      defaultOpenedDetails: [],
      showDetailIcon: true,
      isLoaded: false,
      dummyCount: 24,

      params: {
        search: '',
      },
      pagination: {
        current: 1,
        total: '',
        perPage: '',
        last_page: '',
      },

    }
  },
  methods: {

    loadChats() {
      this.getChats();
    },

    bindings() {

    },

    getChats() {
      this.isLoaded = false;
      repository.getChats(this.pagination.current, JSON.parse(JSON.stringify(this.params)), chats => {
        if (chats.data) {
          this.pagination.perPage = chats.data.per_page;
          this.pagination.total = chats.data.total;
          this.pagination.last_page = chats.data.last_page;
          this.$nextTick(() => {
            this.isLoaded = true;
            this.chats = chats.data.data;
          });

          if (chats.data.length === 0) {
            this.empty();
          }
        }
      });
    },


    empty() {
      this.$buefy.notification.open({
        message: 'Никто тебе не пишет',
        type: 'is-info',
        hasIcon: true,
        duration: 5000,
      })
    },
  },
  watch: {
    'pagination.current': {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          console.log(val, oldVal);

          this.loadChats();
        }
      },
    },
    'params': {
      handler: function (val, oldVal) {
        console.log(val, oldVal);
        this.loadChats();
      },
      deep: true,
    },

  },
  created: function () {
    this.loadChats();
    this.bindings();
  }
}
</script>

