import axios from 'axios';
import config from '../config';

export default {
     upload (user_id, file) {
        let data = new FormData();
        console.log(file);
        data.append('file', file, 'asd');
        return axios.post(config.api_url + '/files/upload/' + user_id, data);
    }
}
