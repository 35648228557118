<template>
    <div class="">
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true" v-if="isLoading"></b-loading>
        <div class="newQuestion question-item" v-if="this.newQuestionLocal">
            <b-message class="question-item-quote mb-0" v-html="this.newQuestionLocal.text"  type="is-info"></b-message>
            <b-field
                label="Вопрос"
                label-position="on-border"
                class="mb-0 question-item-textarea"
            >
                <b-input type="textarea" class="mb-0" :has-counter="false" v-model="newQuestionLocal.question"></b-input>
            </b-field>
            <b-button
                    type="is-primary"
                    size="is-small"
                    :disabled="newQuestionLocal.question.length === 0"
                    @click="addQuestionLocal()"
                    icon-pack="fas"
                    icon-right="question-circle">
                Отправить
            </b-button>
        </div>
        <div class="is-expanded flex-wide" v-if="items.length > 0">
            <div class="is-clearfix pb-2 pt-2">
            <span class="is-block has-text-grey is-size-7 is-pulled-left">{{this.toggled ? 'Решенные вопросы' : 'Вопросы'}}:</span>
            <span class="is-block has-text-grey is-clickable is-size-7 is-pulled-right" v-if="toggleCount > 0" @click="toggled = !toggled">{{this.toggled ? 'Актуальные' : 'Решенные'}}</span>
            </div>
            <QuestionItem
                    v-for="item of items"
                    v-bind:key="item.id"
                    :item="item"
                    :setMouseLeaveQuestion="setMouseLeaveQuestion"
                    :setMouseOverQuestion="setMouseOverQuestion"
                    :addAnswer="addAnswer"
                    :resolveQuestion="resolveQuestion"
                    v-model="answer"
                    :goTo="gotoLocal"
                    :user="user"
            />
        </div>

    </div>
</template>

<script>
    //import BIcon from "buefy/src/components/icon/Icon";
    import repository from '@/repositories/questionRepository.js';
    import BButton from "buefy/src/components/button/Button";
    import QuestionItem from "./QuestionItem";
    export default {
        name: 'QuestionList',
        props: ['newQuestion', 'updateTask', 'goTo', 'user'],
        components: {
            QuestionItem,
            BButton
        },
        data () {
            return {
                items: [],
                toggleCount: 0,
                answer: '',
                paginate: {
                    current: 1,
                },
                toggled: false,
                isLoading: false,
                newQuestionLocal: this.newQuestion,
            }
        },
        methods: {
            setMouseOverQuestion(ref_id) {
                let el = document.querySelector('span[ref="ref_'+ref_id+'"]');
                if (el) {
                    el.setAttribute('class', 'marker');
                }
            },

            setMouseLeaveQuestion(ref_id) {
                let el = document.querySelector('span[ref="ref_'+ref_id+'"]');
                if (el) {
                    el.removeAttribute('class');
                }
            },
            getQuestions() {
                this.isLoading = true;
                repository.getQuestions(this.$route.params.id, this.paginate.current, result => {
                    this.items = result.data.questions.data;
                    this.isLoading = false;
                    this.toggleCount = result.data.toggleCount;
                })
            },
            getResolvedQuestions() {
                this.isLoading = true;
                repository.getResolvedQuestions(this.$route.params.id, this.paginate.current, result => {
                    this.items = result.data.questions.data;
                    this.isLoading = false;
                    this.toggleCount = result.data.toggleCount;
                })
            },
            addQuestionLocal() {
                repository.addQuestion(this.$route.params.id, this.newQuestionLocal.ref, this.newQuestionLocal.text, this.newQuestionLocal.question, result => {
                    console.log(result);
                    this.updateTask();
                    this.newQuestionLocal = false;
                    this.getQuestions();
                })
            },
            resolveQuestion(q_id) {
                repository.resolveQuestion(q_id, result => {
                    let els = document.querySelectorAll('span[ref]');
                    [].forEach.call(els, function(el) {
                        el.removeAttribute('class');
                    });
                    console.log(result);
                    this.getQuestions();
                })
            },
            addAnswer(q_id) {
                repository.addAnswer(q_id, this.answer, result => {
                    console.log(result);
                    this.getQuestions();
                })
            },
            gotoLocal(ref) {
                this.goTo(ref);
            },
        },
        created () {
            this.getQuestions();
        },
        watch: {
            'newQuestion': {
                handler: function (val) {
                    this.newQuestionLocal = val;
                },
                deep: true
            },
            'toggled': {
                handler: function(val) {
                    if (val === true) {
                        this.getResolvedQuestions();
                    } else {
                        this.getQuestions();
                    }
                }
            }
        },
    }
</script>
<style lang="scss">
    .questionListComponent {
        max-height: 100vh;
        overflow-y: scroll;
    }
    .question-item {
        &-footer {
            display: flex;
            margin-bottom: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background: #f5f5f5;
            font-size: 14px;
            &-item {
                padding: 0.5rem;
                display: block;
                flex: 2;
                text-align: center;
                cursor: pointer;
                border-right: 1px solid #d8d8d8;
                border-radius: 0;
                &:hover {
                    border-bottom-left-radius: 4px;
                    background: #eee;
                }
                &:last-child {
                    border-right: 0;
                    border-bottom-right-radius: 4px;
                }
                &:first-child {
                    border-bottom-left-radius: 4px;
                }
            }
        }
        &-live {
            &:hover {
                .question-item-quote {
                    background: #3298dc;
                    color:#fff;
                }
            }
        }
        &-quote {
            padding: 1rem;
            font-size: 13px;
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            cursor: pointer;
        }
        &-answer {
            border-bottom: 1px solid #d8d8d8;
        }
        &-text {
            padding: 0.5rem 1rem;
            font-size: 13px;
            margin-bottom: 0;
            border-radius: 0;
            border-bottom: 1px solid #d8d8d8;
            background: #f5f5f5;
            &:last-child {
                border-bottom: none;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &-body {
                border-left: 2px solid #ccc;
                padding: 0 0 0 0.3rem;
            }
        }

        &-textarea {
            & .control {
                margin-bottom: 0;
            }
            & textarea {
                border-radius: 0;
            }
        }
    }
    @media (max-width: 680px) {
        .questionListComponent {
            max-height: 100vh;
            overflow-y: scroll;
            position: relative !important;
            left: 0 !important;
            top: 0 !important
        }
    }
</style>
