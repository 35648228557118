<template>
    <section class="pt-2">
        <b-table
                :data="projects"
                :columns="columns"

                focusable
                ref="table"
                per-page="5"
                :opened-detailed="defaultOpenedDetails"
                detailed
                detail-key="id"
                @details-open="(row) => $buefy.toast.open(`Expanded ${row.id}`)"
                :show-detail-icon="showDetailIcon"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page">

            <b-table-column field="created_at" label="Дата создания" sortable centered v-slot="props">
                <span class="tag is-success">
                    {{ new Date(props.row.created_at).toLocaleDateString() }}
                </span>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <figure class="media-left">
                        <p class="image is-64x64">
                            <img src="/static/img/placeholder-128x128.png">
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>{{ props.row.id }}</strong>

                                <br>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Proin ornare magna eros, eu pellentesque tortor vestibulum ut.
                                Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.
                            </p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
    </section>
</template>

<script>

    import repository from '@/repositories/projectRepository.js';

    export default {
        name: 'ProjectList',
        components: {
        },
        data: function() {
            return {
                selected: null,
                columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'title',
                        label: 'Название',
                    },
                    {
                        field: 'body',
                        label: 'Описание',
                    },
                    {
                        field: 'ord',
                        label: 'Порядок',
                    },
                    {
                        field: 'created_at',
                        label: 'Дата создания',
                    }
                ],
                projects: [],
                isLoaded: false,
                defaultOpenedDetails: [],
                showDetailIcon: true,
                dummyCount: 24,
                params: {
                    search: '',
                    sortType: localStorage.getItem('taskSortType') ?? 'byDate',
                },
                activeProject: localStorage.getItem('tabProject') ?? 'all',
                pagination: {
                    current: 1,
                    total: '',
                    perPage: '',
                    last_page: '',
                },
                sortTypes: [
                    {
                        id: 'byDate',
                        title: 'По дате создания'
                    },
                    {
                        id: 'byTitle',
                        title: 'По названию'
                    }
                ],
            }
        },
        methods: {
            changeSorting(event) {
                localStorage.setItem('taskSortType', event.target.value);
                this.params.sortType = event.target.value;
            },
            loadProjects() {
                this.getProjects();
            },

            getProjects() {
                this.isLoaded = false;
                repository.getProjects(this.pagination.current, JSON.parse(JSON.stringify(this.params)), tasks => {
                    if (tasks.data) {
                        this.pagination.perPage = tasks.data.per_page;
                        this.pagination.total = tasks.data.total;
                        this.pagination.last_page = tasks.data.last_page;
                        this.$nextTick(() => {
                            this.isLoaded = true;
                            this.projects = tasks.data.data;
                        });

                        if (tasks.data.length === 0) {
                            this.empty();
                        }
                    }
                });
            },


            empty() {
                this.$buefy.notification.open({
                    message: 'Проектов пока еще нет. Вы можете создать <router-link to="/projects/add">новый проект</router-link>',
                    type: 'is-info',
                    hasIcon: true,
                    duration: 5000,
                })
            },
        },
        watch: {
            'pagination.current': {
                handler: function (val, oldVal) {
                    if (val && val !== oldVal) {
                        console.log(val, oldVal);

                        this.loadProjects();
                    }
                },
            },
            'params': {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadProjects();
                },
                deep: true,
            },
        },
        created: function () {
            this.loadProjects()
        }
    }
</script>

