<template>
    <div class="card px-2 py-1" ref="component">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true" v-if="isLoading"></b-loading>
        <div class="card-content">
            <div class="columns">
                <div class="column is-two-thirds">
                    <div class="">
                        <QuestionBtn
                                :x="questionBtn.x"
                                :y="questionBtn.y"
                                v-if="questionBtn.isVisible"
                                :createQuestion="createQuestion"
                                :hideQuestionBtn="hideQuestionBtn"
                        />
                        <TextInput
                            :isEdit="edit.title"
                            field="title"
                            v-model="task.title"
                            :editable="isCanEdit"
                            :errors="errors.task.title"
                            classList="title"
                            label="Название задачи"
                            class="mb-2"
                            :changeInputState="changeInputState"/>

                        <TaskStatus
                            :closeTask="closeTask"
                            :deleteTask="deleteTask"
                            :changeTaskStatus="changeTaskStatus"
                            :canEdit="task && user && task.author_id === user.id || user && user.is_admin"
                            :isAuthor="task && user  && task.author_id === user.id"
                            :isExecutor="task  && user && task.executor_id === user.id"
                            :canDelete="task  && user && user.is_admin"
                            :status_id="task.status_id"
                        />
                        <RichEditor
                                :user="user"
                                :isEdit="edit.body"
                                field="body"
                                v-model="task.body"
                                :editable="isCanEdit"
                                :errors="errors.task.body"
                                :changeInputState="changeInputState"
                                :getSelected="getSelected"
                                ref="richTextEditor"
                        />
                    </div>
                </div>
                <div class="column">
                    <AutocompleteUserInput
                            :isEdit="edit.author_id"
                            field="author_id"
                            v-model="task.author_id"
                            :editable="isCanEdit && user.is_admin === 1"
                            :errors="errors.task.author_id"
                            classList="author_id"
                            label="Постановщик задачи"
                            :searchMethod="getUsers"
                            :changeInputState="changeInputState"
                            :placeholder="$route.params.id ? task.author.name + ' ' + task.author.surname : ''"
                            class="mb-4"
                            v-if="task.author"/>

                    <AutocompleteUserInput
                            :isEdit="edit.executor_id"
                            field="executor_id"
                            v-model="task.executor_id"
                            :editable="isCanEdit"
                            :errors="errors.task.executor_id"
                            classList="executor_id"
                            label="Исполнитель задачи"
                            :searchMethod="getUsers"
                            class="mb-4"
                            :placeholder="$route.params.id ? task.executor.name + ' ' + task.executor.surname : ''"
                            :changeInputState="changeInputState"
                            v-if="task.executor" />
                    <hr/>

                    <AutocompleteProjectInput
                            :isEdit="edit.project_id"
                            field="project_id"
                            v-model="task.project_id"
                            :editable="isCanEdit"
                            :errors="errors.task.project_id"
                            classList="project_id"
                            label="Проект"
                            :searchMethod="getProjects"
                            :placeholder="task.project.title"
                            :changeInputState="changeInputState"
                            v-if="task.project" />

                    <hr/>
                    <DeadLineBlock :task="task" v-if="task.status_id > 2 && task.status_id < 4 && task.finish_at"/>
                    <div ref="questionList">
                        <div ref="questionListComponent" class="questionListComponent">
                            <QuestionList
                                :newQuestion="newQuestion"
                                :updateTask="updateTask"
                                :goTo="goTo"
                                :user="user"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-two-thirds">
                    <div class="">
                        <CommentList v-if="$route.params.id" :updateComments="updateComments" :user="user"/>
                    </div>
                </div>
                <div class="column">

                </div>
            </div>
        </div>
        <div class="card-footer" v-if="!this.$route.params.id">
            <div class="card-footer-item">
                <b-button
                    type="is-primary"
                    @click="addTask"
                    size="is-large"
                >
                    Создать задачу
                    <b-icon pack="fas"
                            icon="tasks"
                            class="ml-1"
                    >
                    </b-icon>
                </b-button>
            </div>

        </div>
    </div>
</template>
<script>
    import TaskStatus from './TaskStatus';
    import userRepository from '@/repositories/userRepository.js';
    import projectRepository from '@/repositories/projectRepository.js';
    import repository from '@/repositories/taskRepository.js';
    import TextInput from "../common/Inputs/TextInput";
    import AutocompleteUserInput from "../common/Inputs/AutocompleteUserInput";
    import AutocompleteProjectInput from "../common/Inputs/AutocompleteProjectInput";
    import RichEditor from "../common/Inputs/RichEditor";
    import DeadLinePopup from "@/components/tasks/DeadLinePopup";
    import ResultPopup from "@/components/tasks/ResultPopup";
    import DeclinePopup from "@/components/tasks/DeclinePopup";
    import Popup from "@/components/common/Popup";
    import BButton from "buefy/src/components/button/Button";
    import CommentList from "./comments/CommentList";
    import DeadLineBlock from "./DeadLineBlock";
    import QuestionBtn from "./questions/QuestionBtn";
    import QuestionList from "./questions/QuestionList";

    export default {
        name: 'TaskEdit',
        props: ['tmp', 'user'],
        components: {
            QuestionList,
            QuestionBtn,
            DeadLineBlock,
            CommentList,
            BButton,
            RichEditor,
            TextInput,
            AutocompleteUserInput,
            AutocompleteProjectInput,
            TaskStatus
        },
        data: function() {
            return {
                errors: {
                    task: [],
                },
                task: {},
                edit: {
                    title: false,
                    body: false,
                    author_id: false,
                    executor_id: false,
                    project_id: false,
                },
                questionBtn: {
                    x: 0,
                    y: 0,
                    isVisible: false,
                    text: '',
                    ref: 0,
                },
                newQuestion: false,
                statusActions: [
                    {
                        id: 2,
                        method: this.showDeadLinePopup,
                    },
                    {
                        id: 4,
                        method: this.showResultPopup,
                    },

                    {
                        id: 8,
                        method: this.showDeclinePopup,
                    },

                    {
                        id: 5,
                        method: this.acceptTask,
                    },
                ],
                isLoading: false,
                updateComments: false
            }
        },
        methods: {
            getUsers(name, cb) {
                if (name && name.length > 0) {
                    userRepository.searchUsersByName(name, cb);
                }
            },

            getProjects(name, cb) {
                if (name && name.length > 0) {
                    projectRepository.searchProjectByTitle(name, cb);
                }
            },
            getProjectById(id, cb) {
                if (id && id.length > 0) {
                    projectRepository.getProjectData(id, cb);
                }
            },
            addTask() {
                repository.addTask(this.task, (task) => {
                    if (task.data) {
                        this.task = task.data;
                        //this.$router.push('/tasks/show/' + task.data.id);
                        window.location.replace('/tasks/show/' + task.data.id);
                    }

                    if (task.errors) {
                        this.errors.task = task.errors;
                    }
                })
            },
            showDeadLinePopup(statusId, taskId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: Popup,
                    props: {
                        component: DeadLinePopup,
                        props: {
                            task: this.task,
                            statusId: statusId,
                            taskId: taskId,
                            updateStatus: this.updateStatus,
                        }
                    },
                    hasModalCard: false,
                    customClass: 'popup-crm',
                    trapFocus: false
                })
            },

            showResultPopup(statusId, taskId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: Popup,
                    props: {
                        component: ResultPopup,
                        props: {
                            task: this.task,
                            statusId: statusId,
                            taskId: taskId,
                            updateStatus: this.updateStatus,
                        }
                    },
                    hasModalCard: false,
                    customClass: 'popup-crm',
                    trapFocus: false
                })
            },

            showDeclinePopup(statusId, taskId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: Popup,
                    props: {
                        component: DeclinePopup,
                        props: {
                            task: this.task,
                            statusId: statusId,
                            taskId: taskId,
                            updateStatus: this.updateStatus,
                        }
                    },
                    hasModalCard: false,
                    customClass: 'popup-crm',
                    trapFocus: false
                })
            },

            acceptTask(statusId, taskId) {
                repository.setAccept(taskId, result => {
                    if (result.errors) {
                        this.errors = result.errors;
                    } else {
                        this.updateStatus(statusId, taskId);
                    }
                })
            },

            changeTaskStatus(statusId) {
                if (statusId === null) {
                    statusId = ++this.task.status_id;
                }

                let taskId = this.$route.params.id;
                let callBack = this.statusActions.find(x => x.id === statusId);
                if (callBack) {
                    callBack.method(statusId, taskId);
                } else {
                    this.updateStatus(statusId, taskId)
                }


            },
            updateStatus(statusId, taskId) {
                repository.updateStatus(taskId, statusId, status => {
                    console.log(status);
                    if (status !== false) {
                        this.task.status_id = status.status;
                        this.$buefy.toast.open({
                            message: 'Статус успешно изменен',
                            type: 'is-success'
                        });
                        this.getTask(taskId, () => {
                            this.updateComments = true;
                        });

                        this.$nextTick(() => {
                            this.updateComments = false;
                        });
                    }
                })
            },

            closeTask() {
                let taskId = this.$route.params.id;

                repository.closeTask(taskId, result => {
                    if (result === true) {
                        this.$buefy.toast.open({
                            message: 'Статус успешно изменен',
                            type: 'is-success'
                        })
                    }
                })
            },

            deleteTask() {
                let taskId = this.$route.params.id;

                this.$buefy.dialog.confirm({
                    message: 'Действительно удалить задачу?',
                    confirmText: 'Да',
                    cancelText: 'Нет',
                    onConfirm: () => repository.deleteTask(taskId, result => {
                        if (result) {
                            this.$router.push('/tasks');
                            this.$buefy.toast.open({
                                message: 'Задача успешно удалена',
                                type: 'is-success'
                            })
                        }
                    })
                })


            },
            getSelected() {
                if (this.task.status_id > 4) {
                    return;
                }

                let selection = window.getSelection();
                if (selection) {
                    console.log(selection, selection.rangeCount );
                    if (selection.rangeCount && selection.toString().length > 0) {
                        let range = selection.getRangeAt(0);
                        let rect = range.getBoundingClientRect();
                        let rand = Math.floor(Math.random() * Math.floor(999999));
                        if (selection.anchorNode.parentElement === selection.focusNode.parentElement) {
                            let html = document.createElement("span");
                            html.setAttribute('ref', 'ref_' + rand);
                            let text = document.createTextNode(selection.toString());
                            html.appendChild(text);
                            range.deleteContents();
                            range.insertNode(html);
                        }

                        this.showQuestionBtn(rect.x, rect.y + window.scrollY, selection.toString(), rand)
                    } else {
                        this.hideQuestionBtn();
                    }
                }
            },
            showQuestionBtn(x, y, text, ref) {
                if (text.length > 0) {
                    console.log(x, y, text);
                    let node = this.$refs['richTextEditor'].$refs['target'].innerHTML;
                    this.task.body = node;
                    this.questionBtn.isVisible = true;
                    this.questionBtn.x = x - 200;
                    this.questionBtn.y = y;
                    this.questionBtn.text = text;
                    this.questionBtn.ref = ref;
                } else {
                    this.questionBtn.isVisible = false;
                }
            },
            hideQuestionBtn() {
                this.questionBtn.isVisible = false;
                //this.newQuestion = false;
            },
            createQuestion() {
                this.newQuestion = {
                    text: this.questionBtn.text,
                    ref: this.questionBtn.ref,
                    question: ''
                };
                this.hideQuestionBtn();
            },
            changeInputState(field) {
                this.edit[field] = !this.edit[field];
                let taskId = this.$route.params.id;
                if (taskId && !this.edit[field]) {
                    this.updateTask()
                }
            },

            updateTask() {
                let taskId = this.$route.params.id;
                repository.updateTask(taskId, this.task, task => {
                    this.task = task.data;
                    this.$buefy.toast.open({
                        message: 'Успешно изменено',
                        type: 'is-success'
                    })
                })
            },
            getTask(taskId, cb) {
                this.isLoading = true;
                repository.getTask(taskId, task => {
                    this.task = task.data;
                    this.isLoading = false;
                    this.$nextTick(() => {
                        console.log(this.$refs);
                    });


                });

                if (cb) {
                    cb();
                }
            },
            handleScroll () {
                this.getPos();
            },
            getPos() {

                const top = this.$refs['questionList'].getBoundingClientRect().top;
                const left = this.$refs['questionList'].getBoundingClientRect().left;
                //const height = this.$refs['questionList'].offsetHeight - 80;
                const width = this.$refs['questionList'].offsetWidth;

                if (top < 0) {
                    this.$refs['questionListComponent'].setAttribute('style', 'position: fixed; left: ' + left + 'px; top: 0px; z-index: 10; width: ' + width + 'px');
                } else {
                    this.$refs['questionListComponent'].setAttribute('style', '');
                }

            },
            goTo(ref) {
                let el = document.querySelector('span[ref="ref_'+ref+'"]');
                if (el) {
                    let top = el.getBoundingClientRect().top;
                    window.scrollTo({
                        top: top,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            },
        },
        watch: {
            'edit': {
                handler: function (val) {
                    console.log(val)
                },
                deep: true
            }
        },

        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        created: function () {
            let taskId = this.$route.params.id;
            if (taskId) {
                this.getTask(taskId);
            } else {
                this.task = {
                    title: '',
                    body: '',
                    author: {
                        name: '',
                    },
                    executor: {
                        name: ''
                    },
                    project: {
                        title: ''
                    }
                };

                this.edit = {
                    title: true,
                    body: true,
                    author_id: true,
                    executor_id: true,
                    project_id: true,
                };


                if (this.$route.query.project_id !== 'all') {
                    this.getProjectById(this.$route.query.project_id, result => {
                        this.task.project_id = result.data.id;
                        this.task.project = result.data;
                    });
                }
            }

            window.addEventListener('scroll', this.handleScroll);
        },
        computed: {
            isCanEdit: function() {
                return (!this.$route.params.id || (this.task && this.user && this.task.author_id === this.user.id && this.task.status_id < 1));
            }
        }
    }
</script>
<style lang="scss">
    .edit-field {
        opacity: 0.5;
        transition: all 0.3s;
        &:hover {
            opacity: 1;
        }
    }
    @media (max-width: 680px) {

        .card-content {
            padding: 1.5rem 0.5rem;
        }
    }
</style>

